import { css } from 'styled-components';

import grab from './assets/grab.png';
import grab2x from './assets/grab2x.png';
import hand from './assets/hand.png';
import hand2x from './assets/hand2x.png';

export default css`
  html.body-lock body {
    overflow: hidden;
  }

  #bee-canvas {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    clip-path: inset(0 0 0 0);
    transition: all 1.4s cubic-bezier(1, 0, 0, 1);
    background: #fff;
  }
  #bee-canvas canvas {
    transform: translate3d(0, 0, 0);
  }
  .hand-cursor {
    cursor: url(${hand}), pointer;
    cursor: -webkit-image-set(url(${hand}) 1x, url(${hand2x}) 2x), auto;
  }
  .grab-cursor {
    cursor: url(${grab}), pointer;
    cursor: -webkit-image-set(url(${grab}) 1x, url(${grab2x}) 2x), auto;
  }
  #holdProgress {
    position: fixed;
    bottom: 40px;
    right: 5%;
    width: 50px;
    height: 50px;
    pointer-events: none;
    z-index: 0;
  }
  #holdProgress circle {
    stroke-dashoffset: 0;
    stroke-width: 2px;
  }
  #holdProgress #hpBar {
    stroke: #111721;
    stroke-dashoffset: 151;
  }
  #holdProgress #hpBar.adding {
    transition: stroke-dashoffset 3s linear;
    stroke-dashoffset: 0;
  }

  .scrolltofirst {
    position: absolute;
    bottom: 40px;
    left: 50px;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.8s linear;
  }
  .scrolltofirst h6 {
    font-size: 0.8em;
  }
  .scrolltofirst.fade {
    opacity: 1;
  }
  .scroll-btn {
    position: relative;
    size: 60px;
    overflow: hidden;
  }
  @media screen and (max-width: 720px) {
    .scroll-btn {
      display: none;
    }
  }
  .scroll-btn .move-to-home {
    top: 0;
  }
  .scrollmagic-pin-spacer {
    background-color: white;
  }
  #h1-sec {
    width: 100%;
    position: absolute;
    height: calc(100vh - 92px);
    justify-content: center;
    align-items: center;
    display: flex;
  }
  @media screen and (max-width: 720px) {
    #h1-sec .center-left-content {
      width: 100%;
      transform: translateY(0);
      top: auto;
      bottom: 60px;
    }
  }
  .scroll-line {
    width: 2px;
    height: 100%;
    background-color: #242431;
    position: absolute;
    top: 0;
  }
  .scroll-line.sl-1 {
    left: 0;
  }
  .scroll-line.sl-2 {
    left: 8px;
  }
  .scroll-line.sl-3 {
    left: 16px;
  }
  .sl-1 {
    animation: scroll 1.6s infinite;
  }
  .blinking {
    animation: blinking 1.6s infinite;
  }
  .sl-2 {
    animation: scroll 1.6s 0.2s infinite;
  }
  .sl-3 {
    margin-right: 0;
    animation: scroll 1.6s 0.4s infinite;
  }

  /* PRELOADER */
  
  #preloader {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999999999;
  }
  .preloader-bg {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: left center;
  }
  .preloader-bg.shift {
    transform-origin: left center;
  }
  .plbg-1 {
    background: #FFBA1C;
  }
  .plbg-2 {
    background-color: white;
  }
  .preloader-content {
    width: 120px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
  .plc-index {
    position: relative;
    text-align: center;
    top: -4px;
    left: 6px;
    transform: scale(1.1);
    opacity: 0;
    transition: transform 4000ms;
    z-index: 100000;
  }

  /* Loader */

  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: loaded 1000ms 3000ms forwards;
    z-index: 1000;
  }
  .loader svg {
    animation: rotate 1000ms linear infinite;
  }
  .loader svg circle {
    fill: transparent;
    stroke: #ffc133;
    stroke-width: 3px;
    stroke-linecap: round;
    stroke-dasharray: 160;
    stroke-dashoffset: 50;
  }
  @keyframes rotate {
    0% {
      transform: rotate(-360deg);
    }
  }
  @keyframes loaded {
    100% {
      opacity: 0;
    }
  }
`;

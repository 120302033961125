/**
 * Navigation
 * ----------
 * extends: Block, Logo to="/", Informations
 * props:
 *  - items: [arrayOf(objects)] **required**
 */

import React, { useState } from 'react';
import styled, {
  css, createGlobalStyle, ThemeProvider,
} from 'styled-components';
import { themeGet } from 'styled-system';
import { timingFunctions } from 'polished';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import SVGIcon from '../Icon';
import Block from '../Block';
import Logo from '../Logo';
import { Title, Label, Link } from '../Text';
import Informations from '../Footer/Informations';
import Space from '../Space';

import featuredProjects from '../featuredProjects';

import Headroom from './Headroom';
import Icon from './Icon';
import Items from './Items';

const SOCIAL_ITEMS = [
  {
    name: 'facebook',
    url: 'https://www.facebook.com/DXBeeMedia',
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/dxbeemedia',
  },
];

const GlobalStyle = createGlobalStyle`
  body {
    ${props => props.active && css`
      overflow: hidden;
      height: 100%;
      width: 100%;
    `}
  }
`;

const StyledHeadroom = styled(Headroom)`
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 999999999999;
`;

const Wrapper = styled.div`
  ${props => props.fixed && css`
    position: fixed;
    width: 100%;
    top: 0; left: 0; right: 0;
    transform: translateY(-100%);
  `}
  ${props => props.transition && css`transition: transform 300ms;`}
  ${props => props.pinned && css`transform: translateY(0);`}
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: ${props => (!props.active
    ? themeGet('colors.white.shade.0')
    : 'transparent')};

  transition: ${props => (!props.active
    ? 'background 400ms 600ms'
    : 'background 0ms')};

  padding: ${themeGet('space.4')};
  pointer-events: all;
`;

const Boxes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  position: fixed;
  z-index: 999999999999;
  right: 4vw;
  top: 10vh;
  bottom: 0;
  width: 70vw;
  height: 100vh;

  opacity: 0;
  transition: opacity 500ms;
  pointer-events: none;
  ${props => props.active && css`
    opacity: 1;
    pointer-events: all;
  `}

  @media(max-width: ${themeGet('breakpoints.1')}) {
    display: none;
  }

  @media(max-width: ${themeGet('breakpoints.3')}) {
    & > a:nth-child(n + 3) {
      display: none;
    }
  }

  @media(max-width: ${themeGet('breakpoints.4')}) {
    & > a:nth-child(n + 4) {
      display: none;
    }
  }

  @media(max-width: ${themeGet('breakpoints.5')}) {
    & > a:nth-child(n + 5) {
      display: none;
    }
  }

  @media(max-width: ${themeGet('breakpoints.6')}) {
    & > a:nth-child(n + 6) {
      display: none;
    }
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 25vw;
  height: 80%;
  margin-right: 20px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;

  opacity: 0;
  transform: translateY(10%);
  transition: 
    transform 1200ms ${timingFunctions('easeOutQuint')}, opacity 800ms;

  ${props => props.active && css`
    opacity: 1;
    transform: translateX(0);
    transition-delay: ${props.index * 150 + 600}ms;
  `}
  
  &:after {
    content: '';
    position: absolute;
    background: ${themeGet('colors.secondary.shade.1')};
    top: 0; right: 0; bottom: 0; left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    transition: opacity 1000ms;
    mask-image:
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  }
  &:hover:after {
    opacity: 0;
  }

  @media(min-width: ${themeGet('breakpoints.3')}) {
    width: 18vw;
  }

  @media(min-width: ${themeGet('breakpoints.4')}) {
    width: 14vw;
  }
`;

const Image = styled(Img)`
  width: 100%;
  height: 100%;
  transform-origin: 0;
  transform: scale(1.1);
  transition: transform 3000ms ${timingFunctions('easeOutExpo')};
  &:hover {
    transform: scale(1);
  }
`;

const Heading = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  pointer-events: none;
  z-index: 1;
  padding: ${themeGet('space.2')};

  & > ${Title} {
    text-transform: uppercase;
  }

  & > ${Label} {
    text-transform: capitalize;
    font-size: ${themeGet('fontSizes.3')};
  }
`;

const WrapperInformations = styled.div`
  position: fixed;
  z-index: 999999999999;
  left: 0; bottom: 0;
  width: 100%;
  height: auto;
  padding-bottom: 21px;

  color: ${themeGet('colors.white.base')};

  opacity: 0;
  pointer-events: none;
  transition: opacity 800ms;

  @media(min-width: ${themeGet('breakpoints.0')}) {
    left: -13px;
    bottom: 50px;
  }
  @media(min-width: ${themeGet('breakpoints.1')}) {
    left: -34px;
    width: auto;
    bottom: 0;
  }

  ${props => props.active && css`
    opacity: 1;
    pointer-events: all;
  `}
`;

const Social = styled.div`
  position: fixed;
  z-index: 999999999999;
  bottom: 130px;
  right: 50%;
  transform: translateX(calc(50% - 6px));

  @media(min-width: ${themeGet('breakpoints.0')}) {
    bottom: 22px;
    transform: translateX(calc(50% - 21px));
  }

  @media(min-width: ${themeGet('breakpoints.1')}) {
    right: 30px;
  }

  opacity: 0;
  transition: opacity 500ms;
  ${props => props.active && css`
    opacity: 1;
  `}

  ${Link}:first-child { margin-right: 15px; } 
`;

const StyledBlock = styled(Block)`
  height: ${props => (props.active ? '100vh' : '92px')};
  z-index: 999999999999;
`;

const Navigation = () => {
  const [active, setActive] = useState(false);

  const portfoliosMapped = featuredProjects()
    .map(item => ({
      title: item.node.frontmatter.title,
      slug: item.node.frontmatter.slug,
      src: item.node.frontmatter.header.cover.image.childImageSharp.fluid,
      type: 'image',
      category: item.node.frontmatter.general.category,
      description: item.node.frontmatter.general.subcategory,
    }));

  return (
    <ThemeProvider theme={{ mode: 'dark' }}>
      <StyledBlock active={active}>
        <GlobalStyle active={active} />
        <StyledHeadroom>
          {({ pinned, fixed, transition }) => (
            <Wrapper
              pinned={pinned}
              fixed={fixed}
              transition={transition}
            >
              <Header active={active}>
                <Logo to="/" alternative={active} />
                <Icon onClick={() => setActive(!active)} active={active} />
              </Header>
            </Wrapper>
          )}
        </StyledHeadroom>
        <Items active={active} />
        <Boxes active={active}>
          {portfoliosMapped.map((item, index) => (
            <AniLink
              key={item.title}
              cover
              to={`/portfolio/${item.slug}`}
              bg="hsl(42,100%,55%)"
            >
              <Box
                active={active}
                index={index}
              >
                <Image
                  alt={item.title}
                  fluid={item.src}
                />
                <Heading>
                  <Label>
                    {item.category}
                  </Label>
                  <Title as="h4">{item.title}</Title>
                </Heading>
              </Box>
              <Space width="1px" />
            </AniLink>
          ))}
        </Boxes>
        <WrapperInformations active={active}>
          <Informations />
        </WrapperInformations>
        <Social active={active}>
          {SOCIAL_ITEMS.map(item => (
            <Link
              key={item.name}
              href={item.url}
              target="_blank"
            >
              <SVGIcon name={item.name} color="hsl(0, 0%, 100%)" />
            </Link>
          ))}
        </Social>
      </StyledBlock>
    </ThemeProvider>
  );
};

Navigation.propTypes = {
  portfolio: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ).isRequired,
};

export default Navigation;

/**
 * Fonts
 * -----
 */

import { FontFaces } from './FontFace';

import SofiaPro100 from '../assets/fonts/sofiapro/sofiapro-100.woff';
import SofiaPro200 from '../assets/fonts/sofiapro/sofiapro-200.woff';
import SofiaPro300 from '../assets/fonts/sofiapro/sofiapro-300.woff';
import SofiaPro400 from '../assets/fonts/sofiapro/sofiapro-400.woff';
import SofiaPro500 from '../assets/fonts/sofiapro/sofiapro-500.woff';
import SofiaPro600 from '../assets/fonts/sofiapro/sofiapro-600.woff';
import SofiaPro700 from '../assets/fonts/sofiapro/sofiapro-700.woff';
import SofiaPro900 from '../assets/fonts/sofiapro/sofiapro-900.woff';

import ltcbodoni from '../assets/fonts/ltcbodoni/ltcbodoni.woff';

export const SofiaPro = FontFaces.create(
  [
    { url: SofiaPro100, weight: 100 },
    { url: SofiaPro200, weight: 200 },
    { url: SofiaPro300, weight: 300 },
    { url: SofiaPro400, weight: 400 },
    { url: SofiaPro500, weight: 500 },
    { url: SofiaPro600, weight: 600 },
    { url: SofiaPro700, weight: 700 },
    { url: SofiaPro900, weight: 900 },
  ],
  { name: 'SofiaPro' },
);

export const LTCBodoni = FontFaces.create(
  [
    { url: ltcbodoni, weight: 300 },
  ],
  { name: 'LTCBodoni' },
);

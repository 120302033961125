/**
 * Link
 * ----
 * extends: Common
 * props:
 *  - active [bool]
 *  - as [string] || [func] (default: GatsbyLink)
 */

import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';

import Common from './Common';

const AltState = () => css`
  color: ${themeGet('colors.primary.base')};
`;

const Link = styled(({ active, ...rest }) => <Common {...rest} />)`
  line-height: 1.3;

  font-weight: ${themeGet('fontWeights.bold')};

  cursor: pointer;
  transition: color 400ms;

  &:hover {
    ${AltState()}
  };
  ${props => (props.active && AltState())};
`;

Link.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string, // tag
    PropTypes.func, // scroll link
    PropTypes.object, // gatsby link
  ]),
};

Link.defaultProps = {
  as: 'a',
};

export default Link;

/**
 * Logo
 * ----
 * props:
 *  - as [string]
 */

import React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import dark from '../../static/img/logo.svg';
import light from '../../static/img/logo-alt.svg';

const Logo = styled.div`
  position: relative;
  z-index: 99999999999999;
  cursor: pointer;

  &:before,
  &:after {
    content: "";
    display: block;
    flex: 0 0 auto;
    width: ${props => props.width || '152px'};
    height: 38px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: opacity 300ms 600ms;

    ${props => props.alternative && css`
      transition: opacity 300ms;
    `}

    @media(min-width: ${themeGet('breakpoints.0')}) {
      width: 200px;
      height: 50px;
    }
  }

  &:before {
    background-image: url(${dark});

    ${props => props.alternative && css`
      opacity: 0;
    `}
  }

  &:after {
    position: absolute;
    top: 0; left: 0;
    background-image: url(${light});

    opacity: 0;
    ${props => props.alternative && css`
      opacity: 1;
    `}
  }
`;

const AnimatedLogo = ({ to, alternative }) => (
  <AniLink cover to={to} bg="hsl(42,100%,55%)">
    <Logo alternative={alternative} />
  </AniLink>
);

export default AnimatedLogo;

/* eslint-disable no-console */
const createjs = require('preload-js');
const $ = require('jquery');
const { TweenMax, Expo } = require('gsap');

class Preloader {
  constructor() {
    this.queue = new createjs.LoadQueue();
    this.dom = {};
    this.progressHandlers = [];
    this.completeHandlers = [];
    this.transitionOutHandlers = [];
    this.queue.on('progress',
      ev => this.progressHandlers.forEach(cb => cb(ev, this)));
    this.queue.on('complete',
      ev => this.completeHandlers.forEach(cb => cb(ev, this)));
    this.queue.on('error', ev => console.log(ev));
    this.queue.setMaxConnections(5);

    // eslint-disable-next-line no-unused-vars
    this.addOnProgress((ev) => {
      $('.plc-number').html(Math.round(this.queue.progress * 100));
    });

    // eslint-disable-next-line no-unused-vars
    this.addOnComplete((ev) => {
      TweenMax.to($('.plc-vc-cover'), 1, {
        scaleX: 0,
        delay: 0.4,
        ease: Expo.easeInOut,
        onComplete: () => {
          this.transitionOutHandlers.forEach(cb => cb(this));
        },
      });
    });
  }

  addOnProgress(cb) {
    this.progressHandlers.push(cb);
  }

  addOnComplete(cb) {
    this.completeHandlers.push(cb);
  }

  addOnTransitionOut(cb) {
    this.transitionOutHandlers.push(cb);
  }
}

module.exports = Preloader;

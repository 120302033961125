/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable max-len */
import MODEL from './model';

const $ = require('jquery');
const { TweenMax, Expo } = require('gsap');
const Bee = require('./dxbee-bee');
const Preloader = require('./dxbee-preloader');

export default class DXBEE {
  constructor() {
    document.documentElement.classList.add('body-lock');

    this.bee = new Bee(this);
    this.preloader = new Preloader();
    this.preload();

    TweenMax.to($('.plc-index'), 1.4, { opacity: 1, scale: 1, ease: Expo.easeOut });

    this.preloader.addOnComplete(() => {
      TweenMax.to($('.plc-index'), 1.3, {
        opacity: 0, scale: 0.4, delay: 1, ease: Expo.easeInOut,
      });
      TweenMax.to($('.plc-spinner'), 0.8, {
        scale: 0,
        delay: 2,
        ease: Expo.easeInOut,
        onComplete: () => {
          $('.plc-spinner').removeClass('active');
        },
      });
      this.bee.loadScene(MODEL);
      TweenMax.to($('.plbg-2'), 0.5, {
        scaleX: 0,
        delay: 2.2,
        ease: Expo.easeInOut,
        onComplete: () => {
          $('.scrolltofirst').addClass('fade');
        },
      });
      TweenMax.to($('.plbg-1'), 1.2, {
        scaleX: 0,
        delay: 2.5,
        ease: Expo.easeInOut,
        onComplete: () => {
          $('.preloader-bg').addClass('shift');
          $('#h1-sec .slideup').each(function (index) {
            const sectionOneHeadingDelay = index / 6;
            TweenMax.to($(this), 1, {
              y: 0, opacity: 1, delay: sectionOneHeadingDelay, ease: Expo.easeOut,
            });
          });
          // document.querySelector('header').classList.remove('init');
          TweenMax.to($('#h1-sec .slideup-ctrl'), 1, {
            opacity: 1, y: 0, delay: 0.8, ease: Expo.easeOut,
          });
          $('#preloader').hide();
        },
      });
    });
    this.preloader.addOnTransitionOut(() => {
      this.bee.transitionIn();
    });
    this.preload();
    this.returnButton = document.querySelector('#return-welcome');
    this.returnButton && this.returnButton.addEventListener('click', () => {
      this.bee.backToScene();
    }, false);

    this.scroolToHomeSectionBtn = document.querySelector('.move-to-home');
    this.scroolToHomeSectionBtn && this.scroolToHomeSectionBtn.addEventListener('click', () => {
      this.bee.beeFly();
      this.bee.nextStageAnimation();
      this.bee.transitionOut();
    }, false);

    this.preloader.addOnTransitionOut(() => {});
  }

  preload = (cb) => {
    const { preloader } = this;
    $('img').each(function (index) {
      preloader.queue.loadFile({
        id: `image-${index}`,
        src: $(this).attr('src'),
      });
    });
    $('.brl').each(function (index) {
      preloader.queue.loadFile({
        id: `background-${index}`,
        src: $(this)
          .css('background-image')
          .replace('url(', '').replace(')', '')
          .replace(/"/gi, ''),
      });
    });
    // preloader.queue.loadFile({
    //   id: 'model',
    //   src: '/model.json',
    // });
  }
}

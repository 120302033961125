/**
 * Items
 * -----
 * extends: Block
 * props:
 *  - active: [bool] **required**
 */

import React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
import { timingFunctions } from 'polished';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import Block from '../Block';

const NAV_LINKS = [
  { name: 'Home', path: '/' },
  { name: 'About us', path: '/about' },
  { name: 'Services', path: '/services' },
  { name: 'Portfolio', path: '/portfolio' },
  { name: 'Contact', path: '/contact' },
];

const Shade = styled.div`
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0; right: 0; bottom: 0; left: 0;
  background: ${themeGet('colors.secondary.base')};

  transition: transform 1000ms 200ms ${timingFunctions('easeOutCirc')};
  transform: ${props => (props.active ? 'translateX(0)' : 'translateX(-100%)')};

  ${props => props.active && css`
    transition: transform 1000ms ${timingFunctions('easeOutCirc')};
  `}
`;

const LinkWrapper = styled.div`
  display: inline-block;
  width: fit-content;
  
  &:not(:last-child) {
    margin-bottom: 25px;
  }

  @media(min-width: ${themeGet('breakpoints.0')}) {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  opacity: 0;
  transform: translateY(-50%);
  transition:
    opacity 300ms,
    transform 1000ms 1000ms ${timingFunctions('easeOutCirc')};
`;


const Link = styled.div`
  transition: background-position 600ms ${timingFunctions('easeInOutQuint')};
  background-size: 200% 100%; 
  background-image:
    linear-gradient(to right,${themeGet('colors.white.base')} 50%,
      ${themeGet('colors.primary.base')} 50%),
    linear-gradient(to right, ${themeGet('colors.secondary.base')} 50%,
      ${themeGet('colors.secondary.base')} 50%);
  -webkit-background-clip: text, border-box;
  background-clip: text, border-box; 
  color: transparent; 

  &:hover {
    background-position: -100% 0; 
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 999999999;
  overflow: hidden;
  font-size: ${themeGet('fontSizes.9')};
  font-weight: ${themeGet('fontWeights.bold')};
  color: ${themeGet('colors.white.base')};
  padding: 0 ${themeGet('space.5')};
  position: fixed;
  top: -50px; bottom: 0; right: 0; left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: ${props => (props.active ? 'all' : 'none')};

  ${props => props.active && css`
    ${LinkWrapper} { 
      transition:
        opacity 500ms,
        transform 1000ms ${timingFunctions('easeOutCirc')},
        background-position 300ms linear;
        opacity: 1;
        transform: translateY(0);

        ${[...Array(5)].map((_item, i) => css`
          &:nth-child(${i + 1}) {
            transition-delay: ${(i * 100) + 300}ms;
          }
        `)}
      }
    `}
`;


const Items = ({ active }) => (
  <Block>
    <Wrapper active={active}>
      {NAV_LINKS.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <LinkWrapper key={index}>
          <Link
            cover
            bg="hsl(42,100%,55%)"
            active={active}
            as={AniLink}
            to={item.path}
          >
            {item.name}
          </Link>
        </LinkWrapper>
      ))}
    </Wrapper>
    <Shade active={active} />
  </Block>
);

Items.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default Items;

/**
 * Icon
 * ----
 * props:
 *  - active: [bool] **required**
 *  - handleOnClick: [func] **required**
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
import { timingFunctions } from 'polished';

import { noJitter } from '../../utils';

const CONF = {
  width: 32,
  height: 2,
  gap: 6,
};

// needed for double calculation for bar positioning
const translate = () => `${(CONF.height + CONF.gap) / 2}px`;

const Wrapper = styled.div`
  padding: 10px;
  margin-right: -10px;
  cursor: pointer;
  z-index: 9999999999999;

  transition: transform 1000ms ${timingFunctions('easeOutCirc')};
  ${props => props.open && css`
    transform: rotate(90deg);
  `}
`;

const Bar = styled.div`
  ${noJitter};
  transition: transform 1000ms ${timingFunctions('easeOutCirc')};

  &:after {
    content: "";
    display: block;
    width: ${CONF.width}px;
    height: ${CONF.height}px;
    background: ${themeGet('colors.black.shade.0')};
    transition:
      transform 1000ms ${timingFunctions('easeOutCirc')},
      opacity 300ms, background 300ms;
  }

  &:not(:last-child) {
    margin-bottom: ${CONF.gap}px
  }

  ${props => props.open && css`
    &:after {
      background: ${themeGet('colors.white.base')};
      transition:
        transform 1000ms 100ms ${timingFunctions('easeOutCirc')},
        opacity 300ms, background 300ms 600ms;
    }
    
    &:nth-child(1) {
      transform: translateY(${translate()});
      &:after { transform: translateY(${translate()}) rotate(45deg); }
    }

    &:nth-child(2) {
      &:after{ opacity: 0; }
    }

    &:nth-child(3) {
      transform: translateY(-${translate()});
      &:after { transform: translateY(-${translate()}) rotate(-45deg); }
    }
  `}
`;

const Icon = ({ onClick, active }) => (
  <Wrapper onClick={onClick} open={active}>
    {[1, 2, 3].map(item => <Bar key={item} open={active} />)}
  </Wrapper>
);

Icon.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Icon;

/**
 * GlobalStyle
 * -----------
 * props:
 *  - children: [node] **required**
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import reset from 'styled-reset-advanced';

import { SofiaPro, LTCBodoni } from './Fonts';
import beeStyles from './Bee/styles';
import theme from '../theme';

const Global = createGlobalStyle`
  ${reset};
  ${beeStyles};

  #___gatsby,
  #gatsby-focus-wrapper,
  .tl-edges,
  .tl-wrapper,
  [class^="GlobalStyle__App"] {
    overflow: hidden;
  }

  body section [class^="Icon__Wrapper"] {
    display: none;
  }

  .tl-wrapper--unmount {
  /*
    Just make sure animation time is same that transition duration in
    <AniLink /> as well add some delay (e.g. 0.2s as in example) to avoid
    weird blinking at the end
  */
  animation: 1s ease-out 0.2s normal fadeout;
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    z-index: 0;
  }
  100% {
    opacity: 0;
    z-index: 0;
  }
}
`;

const App = styled.div`
  font-family: 'SofiaPro', 'Helvetica Neue', 'Helvetica', sans-serif;
`;

const GlobalStyle = ({ children }) => (
  <ThemeProvider theme={{ ...theme, mode: 'light' }}>
    <>
      <SofiaPro />
      <LTCBodoni />
      <Global />
      <App>
        {children}
      </App>
    </>
  </ThemeProvider>
);

GlobalStyle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalStyle;

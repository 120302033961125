/**
 * Space
 * -----
 * Main component
 */

import styled from 'styled-components';
import { space, width, height } from 'styled-system';

const Space = styled.div`
  ${space}
  ${width}
  ${height}
`;

export default Space;

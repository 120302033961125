import React from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import Layout from '../components/Layout';
import Block from '../components/Block';
import Sitewidth from '../components/Sitewidth';
import Button from '../components/Button';
import Round from '../components/Button/Round';
import { Title, Label } from '../components/Text';

import Clients from '../components/Clients';
import Swiping from '../components/Slider/Swiping';
import Sliding from '../components/Slider/Sliding';
import Projects from '../components/Projects';
import Contact from '../components/Footer/Contact';
import AnimationHorizontal from '../components/Animate';

import video from '../assets/videos/hero-1.mp4';

import photo1 from '../assets/images/mobile-apps.jpg';
import photo2 from '../assets/images/embedded-software.jpg';
import photo3 from '../assets/images/web-development.jpg';

import BeeComponent from '../components/Bee/Component';
import featuredProjects from '../components/featuredProjects';

const FILES = [
  {
    src: photo1,
    type: 'static',
    title: 'Mobile Apps',
    description: 'Explore the services that we have to offer.We push to exceed expectations when it comes to every aspect of your digital presence, building online and offline experiences.',
  },
  {
    src: photo2,
    type: 'static',
    title: 'Embeded Software',
    description: 'Explore the services that we have to offer. We push to exceed expectations when it comes to every aspect of your digital presence, building online and offline experiences.',
  },
  {
    src: photo3,
    type: 'static',
    title: 'Web Development',
    description: 'Explore the services that we have to offer. We push to exceed expectations when it comes to every aspect of your digital presence, building online and offline experiences.',
  },
];

const Wrapper = styled.div`
  width: 100%;
  transform: translateY(calc(100vh - 92px));
  background: white;
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled(Block)`
  max-width: 900px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 10px;
`;

const VideoWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 900px;

  display: none;
  @media(min-width: ${themeGet('breakpoints.0')}) {
    display: block;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
`;

const ClientsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding-bottom: ${themeGet('space.6')};
`;

const SliderWrapper = styled(Block)``;

const Center = styled.div``;

const IndexPage = () => {
  const portfoliosMapped = featuredProjects()
    .map(item => ({
      title: item.node.frontmatter.title,
      src: item.node.frontmatter.header.cover.image.childImageSharp.fluid,
      type: 'image',
      category: item.node.frontmatter.general.category,
      description: item.node.frontmatter.general.subcategory,
    }));

  return (
    <Layout>
      <BeeComponent />
      <Wrapper id="site">
        <Sitewidth pt={[4, 5]}>
          <TextWrapper>
            <Round
              id="return-welcome"
              className="btn btn-primary btn-sq-dark"
              direction="up"
            />
            <AnimationHorizontal direction="btt">
              <StyledTitle>
                We launch award-winning client
                ventures and experiences that last
              </StyledTitle>
            </AnimationHorizontal>
            <Center>
              <AnimationHorizontal direction="btt" delay={100}>
                <Label>
                  - bridging the gap between online
                  & offline to deliver seamless products -
                </Label>
              </AnimationHorizontal>
            </Center>
          </TextWrapper>
          <AnimationHorizontal direction="btt" delay={200}>
            <VideoWrapper>
              <Video autoPlay loop muted src={video} />
            </VideoWrapper>
          </AnimationHorizontal>
          <AnimationHorizontal direction="btt" delay={300}>
            <ClientsWrapper>
              <Clients />
              <AniLink cover to="/about" bg="hsl(42,100%,55%)">
                <Button data-text="Our story" />
              </AniLink>
            </ClientsWrapper>
          </AnimationHorizontal>
          <AnimationHorizontal direction="btt">
            <SliderWrapper py={[4, 5, 6]}>
              <Swiping
                label="We specialize in"
                files={FILES}
                showButton
                dataText="Our services"
                to="/services"
              />
            </SliderWrapper>
          </AnimationHorizontal>
        </Sitewidth>
        <Projects
          title="Our ventures, products and sub-companies"
          label=""
          quote="- things we're proud of -"
          color="default"
        />
        <Sitewidth>
          <AnimationHorizontal>
            <SliderWrapper py={[5, 6, 7]}>
              <Sliding
                showControls
                showButton
                to="/portfolio"
                dataText="All projects"
                label="Featured projects"
                files={portfoliosMapped}
              />
            </SliderWrapper>
          </AnimationHorizontal>
        </Sitewidth>
        <Contact
          label="Ready to buzz?"
          title="Get in touch"
          quote="Got big ideas?
            Something that you know could change the game, make a difference,
            or spark a revolution? Feel free to reach out to us."
          color="default"
          dataText="Contact us"
          to="/contact"
        />
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

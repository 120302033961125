import React from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import PropTypes from 'prop-types';

import Block from '../Block';
import Slider from './Slider';
import TextStack from './TextStack';
import MediaStack from './MediaStack';
import Controls from './Controls';
import Space from '../Space';

const Container = styled(Block)`
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  @media(max-width: ${themeGet('breakpoints.2')}) {
    flex-direction: column;
  }
`;

const Sliding = ({
  files, label, showControls, showButton, to, dataText,
}) => (
  <Container>
    <Slider count={files.length}>
      {props => (
        <Wrapper>
          <TextStack
            {...props}
            showButton={showButton}
            to={to}
            dataText={dataText}
            label={label}
            files={files}
          />
          {showControls && <Space width="40px" height="40px" />}
          <MediaStack {...props} files={files} />
          {showControls && <Controls {...props} />}
        </Wrapper>
      )}
    </Slider>
  </Container>
);

Sliding.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  dataText: PropTypes.string.isRequired,
  showControls: PropTypes.bool,
  showButton: PropTypes.bool,
  to: PropTypes.string,
};

Sliding.defaultProps = {
  showControls: false,
  showButton: false,
  to: '/',
};

export default Sliding;

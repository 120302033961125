import React, { PureComponent } from 'react';
import posed from 'react-pose';
import { value, spring } from 'popmotion';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

// const VELOCITY_THRESHOLD = 600;
// const DISTANCE_PERCENTILE_THRESHOLD = 0.3;

const Draggable = posed.div({
  draggable: 'x',
  rest: {
    x: ({ offset }) => -offset,
    transition: {
      type: 'spring',
      stiffness: 80,
      damping: 16,
    },
  },
  dragEnd: {
    transition: ({
      from, to, velocity, offset,
    }) => spring({ from, to: -offset, velocity }),
  },
});

const draggableStyle = {
  display: 'flex',
  height: '100%',
  flexWrap: 'nowrap',
};

function childrenBox(root, index) {
  const el = root.children[index];
  return {
    offset: el.offsetLeft,
    width: el.offsetWidth,
  };
}

class Slider extends PureComponent {
  static defaultProps = {
    duration: 3000,
    onDragStart() {},
    onDragEnd() {},
    onTransitionEnd() {},
  };

  static getDerivedStateFromProps({ slideIndex }, { root }) {
    return root ? childrenBox(root, slideIndex) : null;
  }


  state = {
    root: null,
    offset: 0,
    width: 0,
  };

  x = value(0);

  preventClick = false;

  constructor(props) {
    super(props);
    this.adjustCurrentBox = debounce(this.adjustCurrentBox, 250);
  }

  componentDidMount() {
    window.addEventListener('resize', this.adjustCurrentBox);
    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchmove', this.preventTouch, { passive: false });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.adjustCurrentBox);
    window.removeEventListener('touchstart', this.touchStart);
    window.removeEventListener('touchmove', this.preventTouch, {
      passive: false,
    });
  }

  preventTouch = (e) => {
    const minValue = 5; // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX;
    this.clientY = e.touches[0].clientY - this.firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(this.clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  }

  adjustCurrentBox = () => {
    const { slideIndex } = this.props;
    const { root } = this.state;
    this.setState(childrenBox(root, slideIndex));
  };

  goToNextSlide = () => {
    const { slideIndex } = this.props;
    this.goToSlide(slideIndex + 1);
  };

  goToPreviousSlide = () => {
    const { slideIndex } = this.props;
    this.goToSlide(slideIndex - 1);
  };

  goToNewSlide = (n) => {
    const { slideIndex } = this.props;
    this.goToSlide(slideIndex - n);
  };

  goToSlide = (newSlideIndex) => {
    const { children, onSlideChange } = this.props;
    onSlideChange(
      Math.min(Math.max(newSlideIndex, 0), children.length - 1)
    );
  };

  onDragStart = () => {
    const { onDragStart } = this.props;
    this.preventClick = false;
    onDragStart();
  };

  onDragEnd = () => {
    const { offset, width } = this.state;
    const { onDragEnd } = this.props;
    const start = -offset;
    const distance = this.x.get() - start;
    const velocity = this.x.getVelocity();

    if (distance !== 0) {
      // prevents click from firing in onClickCapture
      this.preventClick = true;

      const calculatedVelocity = Math.round((velocity / 6) / width - 1);
      const calculatedDistance = Math.round(distance / width);


      this.goToNewSlide((calculatedVelocity > 1 || calculatedVelocity < -1)
        ? calculatedVelocity
        : calculatedDistance);
    }

    onDragEnd();
  };

  onClickCapture = (e) => {
    if (this.preventClick) {
      e.stopPropagation();
    }
  };

  registerRootElement = (root) => {
    if (root && !this.state.root) {
      const { slideIndex } = this.props;
      this.setState({
        root,
        ...childrenBox(root, slideIndex),
      });
    }
  };

  touchStart(e) {
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  }

  render() {
    const {
      children, className, style, onTransitionEnd,
    } = this.props;
    const { offset } = this.state;
    const valuesMap = { x: this.x };

    return (
      <div className={className} style={style}>
        <Draggable
          ref={this.registerRootElement}
          values={valuesMap}
          offset={offset}
          style={draggableStyle}
          onClickCapture={this.onClickCapture}
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
          onPoseComplete={onTransitionEnd}
          poseKey={offset}
          pose="rest"
        >
          {children}
        </Draggable>
      </div>
    );
  }
}

Slider.propTypes = {
  slideIndex: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  onSlideChange: PropTypes.func.isRequired,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  className: PropTypes.string.isRequired,
  style: PropTypes.string,
  onTransitionEnd: PropTypes.func,
};

export default Slider;

/**
 * Copyright
 * ---------
 */

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { themeGet } from 'styled-system';

import Block from '../Block';
import Sitewidth from '../Sitewidth';
import { Paragraph } from '../Text';

import photo from '../../../static/img/bee-black.svg';

const Img = styled.img`
  width: 25px;
  height: 25px;
`;

const Wrapper = styled(Block)`
  font-size: ${themeGet('fontSizes.0')};
  border-top: 1px solid ${themeGet('colors.black.shade.4')};
`;

const Copyright = () => (
  <ThemeProvider theme={{ mode: 'light' }}>
    <Wrapper py={[2, 3]}>
      <Sitewidth
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Paragraph>
          All Rights Reserved &copy; {new Date().getFullYear()} DXBee™. DXBee™ is a trademark of BigBuzz Media LLC
        </Paragraph>
        <Img src={photo} alt="logo" />
      </Sitewidth>
    </Wrapper>
  </ThemeProvider>
);

export default Copyright;

/* eslint-disable func-names */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
const $ = require('jquery');
const THREE = require('three');
const { TweenMax, Expo } = require('gsap');
const TWEEN = require('tween.js');
const BeeScene = require('./dxbee-bee-scene');
// const WhenInViewport = require('when-in-viewport');
const Modernizr = typeof window !== 'undefined' ? require('./modernizr') : null;
// const InViewportAnimation = require('./dxbee-in-viewport');
require('./vendor/jquery.parallax-scroll');
require('./vendor/jquery.easing.1.3');


// class InViewportAnimation {
//   constructor(config) {
//     const inViewportElements = Array.prototype.slice.call(
//       document.getElementsByClassName(config.selector)
//     );

//     inViewportElements.forEach(inViewportElement => new WhenInViewport(inViewportElement, (elementInViewport) => {
//       elementInViewport.classList.add('inViewport');
//     }, {
//       threshold: 0 - window.innerHeight / 3,
//     }));
//   }
// }


class Bee {
  constructor(dxbee) {
    this.dxbee = dxbee;
    this.dom = {};
    this.dom.progressBar = document.getElementById('hpBar');
    this.dom.progressSvg = document.getElementById('holdProgress');
    this.dom.clickNHold = document.getElementById('click-n-hold');
    this.anchor = {};
    this.anchor.bee = {};
    this.anchor.target = {};
    this.anchor.cam = {};
    this.anchor.hiveFour = {};
    this.tween = {};
    this.tween.wingLeft = null;
    this.tween.wingRight = null;
    this.tween.beeFloat = null;
    this.tween.cameraPanLeft = null;
    this.tween.cameraPanRight = null;
    this.tween.hiveFour = null;
    this.tween.cameraMoveTwo = null;
    this.tween.targetMoveTwo = null;
  }

  setupScene() {
    this.scene.appendTo(document.getElementById('bee-canvas'));

    this.mouse = {};
    this.mouse.vector = new THREE.Vector2();
    this.mouse.position = {
      x: 0,
      y: 0,
    };

    this.touch = {};
    this.touch.down = {};
    this.touch.up = {};
    this.touch.delta = {};

    this.beeHoldTimeout = null;

    this.status = {};
    this.status.canScroll = false;

    this.intersection = null;

    window.addEventListener('resize', () => {
      if (this.scene.status.scene === 'playing') {
        this.scene.objects.cam.position.z = this.scene.objects.cam.startPositionZ;
        this.scene.objects.target.position.x = this.scene.objects.target.startPositionX;
      }
      this.scene.setSize(
        window.innerWidth,
        window.innerHeight
      );
    });

    this.scene.events.update.push((scene) => {
      // Editor defined scripts
      scene.objects.cam.lookAt(scene.objects.target.position);
      scene.objects.hive1.rotation.y += 0.002;
      scene.objects.hive2.rotation.y -= 0.002;
      scene.objects.hive3.rotation.y += 0.002;
      scene.objects.hive4.rotation.y -= 0.002;
      scene.objects.hive5.rotation.y += 0.002;
      scene.objects.hive6.rotation.y -= 0.002;
      scene.objects.hive7.rotation.y += 0.002;

      // Update statuses on every render frame
      if (!scene.status.beeIsTweening && scene.status.scene == 'playing') {
        scene.objects.bee.rotation.x += (this.mouse.position.y - scene.objects.bee.rotation.x) * 0.02;
        scene.objects.bee.rotation.y += (this.mouse.position.x - scene.objects.bee.rotation.y) * 0.02;
      }

      scene.raycaster.setFromCamera(this.mouse.vector, scene.camera);
      const intersects = scene.raycaster.intersectObjects(scene.scene.children, true);
      if (intersects.length > 0) {
        if (this.intersection != intersects[0].object) {
          this.intersection = intersects[0].object;
          if ([
            scene.objects.head1,
            scene.objects.head2,
            scene.objects.beeBody,
            scene.objects.beeEyes,
            scene.objects.antenna1,
            scene.objects.antenna2,
            scene.objects.wingLeft,
            scene.objects.wingRight,
          ].includes(this.intersection)) {
            scene.status.mouseover = true;
            if (scene.status.bee !== 'flying' && scene.status.scene == 'playing') {
              document.body.className = 'hand-cursor';
            }
          } else {
            scene.status.mouseover = false;
            if (scene.status.bee !== 'flying') {
              document.body.className = '';
            }
          }
        }
      }
    });

    this.events = {};

    this.events.mousedown = (ev) => {
      ev.preventDefault();
      if (
        this.scene.status.bee == 'static'
        && this.scene.status.scene == 'playing'
        && this.scene.status.mouseover == true
        && ev.button == 0
      ) {
        this.beeFly();
        document.body.className = 'grab-cursor';
        this.dom.clickNHold.innerHTML = '- keep holding -';
        // this.dom.clickNHold.className = ' blinking';
        this.dom.progressBar.setAttribute('class', 'adding');
        this.beeHoldTimeout = setTimeout(() => {
          this.dom.progressBar.setAttribute('class', '');
          this.nextStageAnimation();
          this.transitionOut();
        }, 3000);
      }
    };

    this.events.mouseup = (ev) => {
      ev.preventDefault();
      if (
        this.scene.status.bee == 'flying'
        && this.scene.status.scene == 'playing'
        && ev.button == 0
      ) {
        this.beeStopFlying();
        if (this.scene.status.mouseover == true) {
          document.body.className = 'hand-cursor';
        } else {
          document.body.className = '';
        }
        this.dom.clickNHold.innerHTML = '- hold the bee or scroll -';
        // this.dom.clickNHold.className = '';
        this.dom.progressBar.setAttribute('class', '');
        if (this.beeHoldTimeout) {
          clearTimeout(this.beeHoldTimeout);
        }
      }
    };

    this.events.mousemove = (ev) => {
      this.mouse.position.x = (ev.clientX - (this.scene.width / 2)) / 2000;
      this.mouse.position.y = (ev.clientY - (this.scene.height / 2)) / 2000;
      this.mouse.vector.x = (ev.clientX / this.scene.width) * 2 - 1;
      this.mouse.vector.y = -(ev.clientY / this.scene.height) * 2 + 1;
      this.dom.progressSvg.style.left = ev.clientX - 16;
      this.dom.progressSvg.style.top = ev.clientY - 16;
    };

    this.events.mouseout = (ev) => {
      const from = ev.relatedTarget || ev.toElement;
      if (!from || from.nodeName == 'HTML') {
        if (this.scene.status.scene !== 'nextstage') {
          this.events.mouseup(ev);
        }
      }
    };

    this.events.mousewheel = (ev) => {
      if (this.status.canScroll) {
        ev.preventDefault();
        const w = ev.wheelDelta;
        const d = ev.detail;
        let distance;
        if (d) {
          if (w) {
            distance = w / d / 40 * d > 0
              ? 1
              : -1;
          } else {
            distance = -d / 3;
          }
        } else {
          distance = w / 120;
        }
        if (distance < -0.3) {
          this.beeFly();
          this.nextStageAnimation();
        }
      }
    };

    this.events.touchstart = (ev) => {
      if (
        this.scene.status.bee == 'static'
        && this.scene.status.scene == 'playing'
      ) {
        this.beeFly();
        this.dom.progressBar.setAttribute('class', 'adding');
        this.beeHoldTimeout = setTimeout(() => {
          this.dom.progressBar.setAttribute('class', '');
          this.nextStageAnimation();
          this.transitionOut();
        }, 3000);
      }
      this.dom.progressSvg.style.left = ev.targetTouches[0].pageX - 70;
      this.dom.progressSvg.style.top = ev.targetTouches[0].pageY - 70;
      this.touch.down.x = ev.targetTouches[0].pageX;
      this.touch.down.y = ev.targetTouches[0].pageY;
    };

    this.events.touchend = (ev) => {
      if (
        this.scene.status.bee == 'flying'
        && this.scene.status.scene == 'playing'
      ) {
        this.beeStopFlying();
        this.dom.progressBar.setAttribute('class', '');
        clearTimeout(this.beeHoldTimeout);
      }
    };

    this.events.touchmove = (ev) => {
      if (this.scene.status.scene != 'complete') {
        ev.preventDefault();
        ev.stopPropagation();
      }
      this.dom.progressSvg.style.left = ev.targetTouches[0].pageX - 70;
      this.dom.progressSvg.style.top = ev.targetTouches[0].pageY - 70;

      if (!this.touch.down.x || !this.touch.down.y) {
        return;
      }

      this.touch.up.x = ev.targetTouches[0].pageX;
      this.touch.up.y = ev.targetTouches[0].pageY;
      this.touch.delta.x = this.touch.down.x - this.touch.up.x;
      this.touch.delta.y = this.touch.down.y - this.touch.up.y;

      if (this.status.canScroll && Math.abs(this.touch.delta.x) <= Math.abs(this.touch.delta.y)) {
        if (this.touch.delta.y > 1) {
          if (this.scene.status.bee !== 'flying') {
            this.beeFly();
          }
          this.nextStageAnimation();
          this.transitionOut();
          this.dom.progressBar.setAttribute('class', '');
          clearTimeout(this.beeHoldTimeout);
        }
      }
      /* reset values */
      delete this.touch.down.x;
      delete this.touch.down.y;
    };

    this.events.touchmove.options = Modernizr.passiveeventlisteners ? { passive: true } : { passive: false };
    // Attach events
    Object
      .keys(this.events)
      .forEach(eventName => window.addEventListener(
        eventName,
        this.events[eventName],
        {
          passive: false,
          ...(this.events[eventName].options || {}),
        },
      ));
  }

  /**
   * Intro Animations for WebGL
   *
   */
  transitionIn() {
    // animate target to position 1
    const lookAtPositionStart = {
      x: this.scene.objects.target.position.x,
      y: this.scene.objects.target.position.y,
      z: this.scene.objects.target.position.z,
    };
    const lookAtPositionTween = new TWEEN.Tween(lookAtPositionStart)
      .to({ x: 3, y: 50.73, z: -20.28 }, 2000)
      .easing(TWEEN.Easing.Quintic.Out)
      .onUpdate(() => {
        this.scene.objects.target.position.set(lookAtPositionStart.x, lookAtPositionStart.y, lookAtPositionStart.z);
      })
      .start();

    // animate camera to position 1
    const camPositionStart = {
      x: this.scene.objects.cam.position.x,
      y: this.scene.objects.cam.position.y,
      z: this.scene.objects.cam.position.z,
    };
    const camPositionTween = new TWEEN.Tween(camPositionStart)
      .to({ x: 0, y: 100, z: 1000 }, 2000)
      .easing(TWEEN.Easing.Quintic.Out)
      .onUpdate(() => {
        this.scene.objects.cam.position.set(camPositionStart.x, camPositionStart.y, camPositionStart.z);
      })
      .onComplete(() => {
      // animate camera to position 2
        const camPositionStartTwo = {
          x: this.scene.objects.cam.position.x,
          y: this.scene.objects.cam.position.y,
          z: this.scene.objects.cam.position.z,
        };
        this.tween.cameraMoveTwo = new TWEEN.Tween(camPositionStartTwo)
          .to({ x: 186.89, y: 50, z: this.scene.objects.cam.startPositionZ }, 3200)
          .easing(TWEEN.Easing.Quintic.InOut)
          .onUpdate(() => {
            this.scene.objects.cam.position.set(camPositionStartTwo.x, camPositionStartTwo.y, camPositionStartTwo.z);
          })
          .start();

        // animate target to position 2
        const lookAtPositionStartTwo = {
          x: this.scene.objects.target.position.x,
          y: this.scene.objects.target.position.y,
          z: this.scene.objects.target.position.z,
        };
        this.tween.targetMoveTwo = new TWEEN.Tween(lookAtPositionStartTwo)
          .to({ x: this.scene.objects.target.startPositionX, y: 61.99, z: 66.75 }, 3200)
          .easing(TWEEN.Easing.Quintic.InOut)
          .onUpdate(() => {
            this.scene.objects.target.position.set(lookAtPositionStartTwo.x, lookAtPositionStartTwo.y, lookAtPositionStartTwo.z);
          })
          .onComplete(() => {
            this.cameraPanRight();
          })
          .start();

        // hive layer 1 animation
        const hiveLayerOnePosition = {
          x: this.scene.objects.hive1.rotation.x,
          y: this.scene.objects.hive1.position.y,
          z: this.scene.objects.hive1.position.z,
        };
        const hiveLayerOneTween = new TWEEN.Tween(hiveLayerOnePosition)
          .to({ x: 2, y: -240, z: 100 }, 2000)
          .easing(TWEEN.Easing.Quintic.InOut)
          .onUpdate(() => {
            this.scene.objects.hive1.position.set(0, hiveLayerOnePosition.y, hiveLayerOnePosition.z);
            this.scene.objects.hive1.rotation.x = hiveLayerOnePosition.x;
          })
          .start();

        // hive layer 2 animation
        const hiveLayerTwoPosition = {
          x: this.scene.objects.hive2.rotation.x,
          y: this.scene.objects.hive2.position.y,
          z: this.scene.objects.hive2.position.z,
        };
        const hiveLayerTwoTween = new TWEEN.Tween(hiveLayerTwoPosition)
          .to({ x: 1, y: -210, z: 70 }, 2000)
          .easing(TWEEN.Easing.Quintic.InOut)
          .onUpdate(() => {
            this.scene.objects.hive2.position.set(0, hiveLayerTwoPosition.y, hiveLayerTwoPosition.z);
            this.scene.objects.hive2.rotation.x = hiveLayerTwoPosition.x;
          })
          .delay(180)
          .start();

        // hive layer 3 animation
        const hiveLayerThreePosition = {
          x: this.scene.objects.hive3.rotation.x,
          y: this.scene.objects.hive3.position.y,
          z: this.scene.objects.hive3.position.z,
        };
        const hiveLayerThreeTween = new TWEEN.Tween(hiveLayerThreePosition)
          .to({ x: 0.4, y: -180, z: 40 }, 2000)
          .easing(TWEEN.Easing.Quintic.InOut)
          .onUpdate(() => {
            this.scene.objects.hive3.position.set(0, hiveLayerThreePosition.y, hiveLayerThreePosition.z);
            this.scene.objects.hive3.rotation.x = hiveLayerThreePosition.x;
          })
          .delay(360)
          .start();

        // hive layer 4 animation
        const hiveLayerFourPosition = {
          x: this.scene.objects.hive4.scale.x,
          y: this.scene.objects.hive4.scale.y,
          z: this.scene.objects.hive4.scale.z,
          r: this.scene.objects.hive4.rotation.x,
        };
        this.tween.hiveFour = new TWEEN.Tween(hiveLayerFourPosition)
          .to({
            x: 1.7, y: 1.7, z: 1.7, r: 3.13,
          }, 2000)
          .easing(TWEEN.Easing.Quintic.InOut)
          .onUpdate(() => {
            this.scene.objects.hive4.scale.set(hiveLayerFourPosition.x, hiveLayerFourPosition.y, hiveLayerFourPosition.z);
            this.scene.objects.hive4.rotation.x = hiveLayerFourPosition.r;
          })
          .onStart(() => {
            // change the status of scene to playing, and now the page can be scrolled
            this.scene.status.scene = 'playing';
            this.scene.status.bee = 'static';
            this.status.canScroll = true;

            // set values for anchors
            this.anchor.bee = {
              px: -3.2,
              pz: -50,
              rx: 0,
              ry: 0,
            };
            this.anchor.cam = {
              px: 186.89,
              py: 50,
              pz: this.scene.objects.cam.startPositionZ,
            };
            this.anchor.target = {
              px: this.scene.objects.target.startPositionX,
              py: 61.99,
              pz: 66.75,
            };
            this.anchor.hiveFour = {
              s: 1.7,
              rx: 3.13,
              py: 0,
            };
          })
          .delay(720)
          .start();

        // hive layer 5 animation
        const hiveLayerFivePosition = {
          x: this.scene.objects.hive5.rotation.x,
          y: this.scene.objects.hive5.position.y,
          z: this.scene.objects.hive5.position.z,
        };
        const hiveLayerFiveTween = new TWEEN.Tween(hiveLayerFivePosition)
          .to({ x: -0.4, y: 200, z: 60 }, 2000)
          .easing(TWEEN.Easing.Quintic.InOut)
          .onUpdate(() => {
            this.scene.objects.hive5.position.set(0, hiveLayerFivePosition.y, hiveLayerFivePosition.z);
            this.scene.objects.hive5.rotation.x = hiveLayerFivePosition.x;
          })
          .delay(360)
          .start();

        // hive layer 6 animation
        const hiveLayerSixPosition = {
          x: this.scene.objects.hive6.rotation.x,
          y: this.scene.objects.hive6.position.y,
          z: this.scene.objects.hive6.position.z,
        };
        const hiveLayerSixTween = new TWEEN.Tween(hiveLayerSixPosition)
          .to({ x: -0.6, y: 220, z: 60 }, 2000)
          .easing(TWEEN.Easing.Quintic.InOut)
          .onUpdate(() => {
            this.scene.objects.hive6.position.set(0, hiveLayerSixPosition.y, hiveLayerSixPosition.z);
            this.scene.objects.hive6.rotation.x = hiveLayerSixPosition.x;
          })
          .delay(180)
          .start();

        // hive layer 7 animation
        const hiveLayerSevenPosition = {
          x: this.scene.objects.hive7.rotation.x,
          y: this.scene.objects.hive7.position.y,
          z: this.scene.objects.hive7.position.z,
        };
        const hiveLayerSevenTween = new TWEEN.Tween(hiveLayerSevenPosition)
          .to({ x: -2, y: 240, z: 80 }, 2000)
          .easing(TWEEN.Easing.Quintic.InOut)
          .onUpdate(() => {
            this.scene.objects.hive7.position.set(0, hiveLayerSevenPosition.y, hiveLayerSevenPosition.z);
            this.scene.objects.hive7.rotation.x = hiveLayerSevenPosition.x;
          })
          .start();
      })
      .start();
  }

  /**
   * Continuous panning the camera towards left
   *
   */
  cameraPanLeft() {
    const cameraPanLeftPosition = {
      x: this.scene.objects.cam.position.x,
      y: this.scene.objects.cam.position.y,
      z: this.scene.objects.cam.position.z,
    };
    this.tween.cameraPanLeft = new TWEEN.Tween(cameraPanLeftPosition)
      .to({ x: cameraPanLeftPosition.x + 50, y: cameraPanLeftPosition.y, z: cameraPanLeftPosition.z + 50 }, 15000)
      .easing(TWEEN.Easing.Cubic.InOut)
      .onUpdate(() => {
        this.scene.objects.cam.position.set(cameraPanLeftPosition.x, cameraPanLeftPosition.y, cameraPanLeftPosition.z);
      })
      .onComplete(() => {
        this.cameraPanRight();
      })
      .start();
  }

  /**
   * Continuous panning the camera towards right
   *
   */
  cameraPanRight() {
    const cameraPanRightPosition = {
      x: this.scene.objects.cam.position.x,
      y: this.scene.objects.cam.position.y,
      z: this.scene.objects.cam.position.z,
    };
    this.tween.cameraPanRight = new TWEEN.Tween(cameraPanRightPosition)
      .to({ x: cameraPanRightPosition.x - 50, y: cameraPanRightPosition.y, z: cameraPanRightPosition.z - 50 }, 15000)
      .easing(TWEEN.Easing.Cubic.InOut)
      .onUpdate(() => {
        this.scene.objects.cam.position.set(cameraPanRightPosition.x, cameraPanRightPosition.y, cameraPanRightPosition.z);
      })
      .onComplete(() => {
        this.cameraPanLeft();
      })
      .start();
  }

  /**
   * When user click the bee, bee fly animation will be activated.
   *
   */
  beeFly() {
    // left wing fly animation
    this.scene.status.bee = 'flying';
    const wingLeftPosition = {
      r: this.scene.objects.wingLeft.rotation.y,
      x: this.scene.objects.wingLeft.position.x,
      y: this.scene.objects.wingLeft.position.y,
      z: this.scene.objects.wingLeft.position.z,
    };
    this.tween.wingLeft = new TWEEN.Tween(wingLeftPosition)
      .to({
        r: 0, x: 43.15, y: 10, z: -5.36,
      }, 30)
      .repeat(Infinity)
      .yoyo(true)
      .onUpdate(() => {
        this.scene.objects.wingLeft.position.set(wingLeftPosition.x, wingLeftPosition.y, wingLeftPosition.z);
        this.scene.objects.wingLeft.rotation.y = wingLeftPosition.r;
      })
      .start();

    // right wing fly animation
    const wingRightPosition = {
      r: this.scene.objects.wingRight.rotation.y,
      x: this.scene.objects.wingRight.position.x,
      y: this.scene.objects.wingRight.position.y,
      z: this.scene.objects.wingRight.position.z,
    };
    this.tween.wingRight = new TWEEN.Tween(wingRightPosition)
      .to({
        r: 0, x: -25.21, y: 10, z: -2.72,
      }, 30)
      .repeat(Infinity)
      .yoyo(true)
      .onUpdate(() => {
        this.scene.objects.wingRight.position.set(wingRightPosition.x, wingRightPosition.y, wingRightPosition.z);
        this.scene.objects.wingRight.rotation.y = wingRightPosition.r;
      })
      .start();

    // Bee move up and down
    const beeFlyPosition = {
      y: this.scene.objects.bee.position.y,
    };
    this.tween.beeFloat = new TWEEN.Tween(beeFlyPosition)
      .to({ y: beeFlyPosition.y + 20 }, 1200)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .repeat(Infinity)
      .yoyo(true)
      .onUpdate(() => {
        this.scene.objects.bee.position.y = beeFlyPosition.y;
      })
      .start();
  }

  /**
   * When user release the bee, bee fly animation will be stopped.
   *
   */
  beeStopFlying() {
    this.tween.wingLeft.stop();
    this.tween.wingRight.stop();
    this.tween.beeFloat.stop();
    this.scene.status.bee = 'animating';

    const wingTweenTime = 400 - (this.scene.objects.wingRight.rotation.y / this.scene.objects.wingRight.startRotation.y) * 400;

    // return bee to the original position
    const beeReturnPosition = {
      y: this.scene.objects.bee.position.y,
    };
    const beeResetTween = new TWEEN.Tween(beeReturnPosition)
      .to({ y: this.scene.objects.bee.startPosition.y }, 800)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(() => {
        this.scene.objects.bee.position.y = beeReturnPosition.y;
      })
      .start();

    // return right wing to the original position
    const wingRightReturnPosition = {
      r: this.scene.objects.wingRight.rotation.y,
      x: this.scene.objects.wingRight.position.x,
      y: this.scene.objects.wingRight.position.y,
      z: this.scene.objects.wingRight.position.z,
    };
    const wingRightResetTween = new TWEEN.Tween(wingRightReturnPosition)
      .to({
        r: this.scene.objects.wingRight.startRotation.y,
        x: this.scene.objects.wingRight.startPosition.x,
        y: this.scene.objects.wingRight.startPosition.y,
        z: this.scene.objects.wingRight.startPosition.z,
      }, wingTweenTime)
      .onUpdate(() => {
        this.scene.objects.wingRight.position.set(wingRightReturnPosition.x, wingRightReturnPosition.y, wingRightReturnPosition.z);
        this.scene.objects.wingRight.rotation.y = wingRightReturnPosition.r;
      })
      .onComplete(() => {
        this.scene.status.bee = 'static';
      })
      .easing(TWEEN.Easing.Back.Out)
      .start();

    // return left wing to the original position
    const wingLeftReturnPosition = {
      r: this.scene.objects.wingLeft.rotation.y,
      x: this.scene.objects.wingLeft.position.x,
      y: this.scene.objects.wingLeft.position.y,
      z: this.scene.objects.wingLeft.position.z,
    };
    const wingLeftResetTween = new TWEEN.Tween(wingLeftReturnPosition)
      .to({
        r: this.scene.objects.wingLeft.startRotation.y,
        x: this.scene.objects.wingLeft.startPosition.x,
        y: this.scene.objects.wingLeft.startPosition.y,
        z: this.scene.objects.wingLeft.startPosition.z,
      }, wingTweenTime)
      .onUpdate(() => {
        this.scene.objects.wingLeft.position.set(wingLeftReturnPosition.x, wingLeftReturnPosition.y, wingLeftReturnPosition.z);
        this.scene.objects.wingLeft.rotation.y = wingLeftReturnPosition.r;
      })
      .easing(TWEEN.Easing.Back.Out)
      .start();
  }

  /**
   *  Animating the bee and ready for scroll to next section
   *
   */
  nextStageAnimation() {
    this.status.canScroll = false;
    this.scene.status.scene = 'nextstage';

    this.transitionOut();
    // document.getElementById('home-hero-vid').play();
    this.dom.progressBar.setAttribute('class', '');
    document.body.className = '';
    this.dom.clickNHold.innerHTML = ' - hold the bee or scroll -';
    // this.dom.clickNHold.className = '';

    // stop all animations
    if (this.tween.cameraPanLeft != null) {
      this.tween.cameraPanLeft.stop();
      TWEEN.remove(this.tween.cameraPanLeft);
    }
    if (this.tween.cameraPanRight != null) {
      this.tween.cameraPanRight.stop();
      TWEEN.remove(this.tween.cameraPanRight);
    }
    if (this.tween.hiveFour != null) {
      this.tween.hiveFour.stop();
    }
    if (this.tween.cameraMoveTwo != null) {
      this.tween.cameraMoveTwo.stop();
    }
    if (this.tween.targetMoveTwo != null) {
      this.tween.targetMoveTwo.stop();
    }

    // animate hive ring into place
    const hiveFourNextStageStart = {
      x: this.scene.objects.hive4.scale.x,
      y: this.scene.objects.hive4.scale.y,
      z: this.scene.objects.hive4.scale.z,
      b: this.scene.objects.hive4.rotation.x,
      c: this.scene.objects.hive4.position.y,
    };
    this.tween.hiveFour = new TWEEN.Tween(hiveFourNextStageStart)
      .to({
        x: 1,
        y: 1,
        z: 1,
        b: 4.695,
        c: 10,
      }, 2000)
      .easing(TWEEN.Easing.Quintic.InOut)
      .onUpdate(() => {
        this.scene.objects.hive4.scale.set(hiveFourNextStageStart.x, hiveFourNextStageStart.y, hiveFourNextStageStart.z);
        this.scene.objects.hive4.rotation.x = hiveFourNextStageStart.b;
        this.scene.objects.hive4.position.y = hiveFourNextStageStart.c;
      })
      .start();

    // animate camera into place
    const cameraNextStagePosition = {
      x: this.scene.objects.cam.position.x,
      y: this.scene.objects.cam.position.y,
      z: this.scene.objects.cam.position.z,
    };
    const campos2 = {
      x: 100,
      y: 50,
      z: 700,
    };
    this.tween.cameraMoveTwo = new TWEEN.Tween(cameraNextStagePosition)
      .to({
        x: 100,
        y: 50,
        z: 700,
      }, 3000)
      .easing(TWEEN.Easing.Quintic.InOut)
      .onUpdate(() => {
        this.scene.objects.cam.position.set(cameraNextStagePosition.x, cameraNextStagePosition.y, cameraNextStagePosition.z);
      })
      .start();

    // animate target into place
    const lookAtNextStageStart = {
      x: this.scene.objects.target.position.x,
      y: this.scene.objects.target.position.y,
      z: this.scene.objects.target.position.z,
    };
    this.tween.targetMoveTwo = new TWEEN.Tween(lookAtNextStageStart)
      .to({
        x: -0.11,
        y: 82.85,
        z: 4.15,
      }, 3000)
      .easing(TWEEN.Easing.Quintic.InOut)
      .onUpdate(() => {
        this.scene.objects.target.position.set(lookAtNextStageStart.x, lookAtNextStageStart.y, lookAtNextStageStart.z);
      })
      .start();

    // rotate bee into position
    const beeRotationStart = {
      r: this.scene.objects.bee.rotation.x,
      b: this.scene.objects.bee.rotation.y,
    };
    const beeRotationTween = new TWEEN.Tween(beeRotationStart)
      .to({
        r: 0.35,
        b: 0.1,
      }, 800)
      .easing(TWEEN.Easing.Quadratic.Out)
      .delay(900)
      .onUpdate(() => {
        this.scene.objects.bee.rotation.x = beeRotationStart.r;
        this.scene.objects.bee.rotation.y = beeRotationStart.b;
      })
      .start();

    // bee flies forward towards the screen
    const beeFlyForwardStart = {
      x: this.scene.objects.bee.position.x,
      z: this.scene.objects.bee.position.z,
    };
    const beeFlyForwardTween = new TWEEN.Tween(beeFlyForwardStart)
      .to({
        x: 120,
        z: 800,
      }, 1000)
      .easing(TWEEN.Easing.Quadratic.In)
      .delay(1800)
      .onUpdate(() => {
        this.scene.objects.bee.position.x = beeFlyForwardStart.x;
        this.scene.objects.bee.position.z = beeFlyForwardStart.z;
      })
      .onComplete(() => {
        this.beeStopFlying();
        this.scene.objects.bee.position.x = this.anchor.bee.px;
        this.scene.objects.bee.position.z = this.anchor.bee.pz;
        this.scene.objects.bee.rotation.x = this.anchor.bee.rx;
        this.scene.objects.bee.rotation.y = this.anchor.bee.ry;
        this.scene.status.scene = 'complete';
      })
      .start();
  }

  /**
   * Transition to next screen after the next stage animation
   *
   */
  transitionOut() {
    // transition out animations
    $('#h1-sec h2').each(function (index) {
      const taglineDelay = index / 6;
      TweenMax.to($(this), 1, {
        opacity: 0, y: -80, delay: taglineDelay, ease: Expo.easeOut,
      });
    });

    $('#h1-sec .slideup').each(function (index) {
      const firstSectionSlideupDelay = index / 6;
      TweenMax.to($(this), 1, {
        y: -80, opacity: 0, delay: firstSectionSlideupDelay, ease: Expo.easeIn,
      });
    });

    $('.scrolltofirst').removeClass('fade');
    TweenMax.to($('#h1-sec .slideup-ctrl'), 1, {
      opacity: 0,
      y: -80,
      delay: 0.4,
      ease: Expo.easeIn,
      onComplete: () => {
        TweenMax.set($('#h1-sec .slideup-ctrl'), { y: 80, delay: 0.6 });
        TweenMax.set($('#h1-sec .slideup'), { y: 80, delay: 0.6 });
        TweenMax.set($('#bee-canvas'), {
          autoAlpha: 0,
          delay: 0.8,
          duration: 1,
        });
        $('html').delay(2000).queue(function (next) {
          $(this).removeClass('body-lock');
          next();
        });
      },
    });

    TweenMax.to($('#site'), 1.6, {
      y: 0,
      delay: 1.6,
      ease: Expo.easeInOut,
      onComplete: () => {
        this.scene.stop();
      },
    });
  }

  /**
   * Transition back to the Bee scene
   *
   */
  backToScene() {
    if (this.scene.status.scene == 'complete') {
      // document.querySelector('header').classList.remove('active');
      document.querySelector('html').classList.add('body-lock');
      TweenMax.set($('#bee-canvas'), {
        autoAlpha: 1,
        // delay: 0.8,
        duration: 1,
      });

      this.scene.start();
      this.scene.status.scene = 'animating';
      this.anchor.hiveFour = {
        s: 1.7,
        rx: 3.13,
        py: 0,
      };
      const hiveFourBackPositionStart = {
        x: this.scene.objects.hive4.scale.x,
        y: this.scene.objects.hive4.scale.y,
        z: this.scene.objects.hive4.scale.z,
        b: this.scene.objects.hive4.rotation.x,
        c: this.scene.objects.hive4.position.y,
      };
      this.tween.hiveFour = new TWEEN.Tween(hiveFourBackPositionStart)
        .to({
          x: this.anchor.hiveFour.s,
          y: this.anchor.hiveFour.s,
          z: this.anchor.hiveFour.s,
          b: this.anchor.hiveFour.rx,
          c: this.anchor.hiveFour.py,
        }, 2000)
        .easing(TWEEN.Easing.Quintic.InOut)
        .onUpdate(() => {
          this.scene.objects.hive4.scale.set(hiveFourBackPositionStart.x, hiveFourBackPositionStart.y, hiveFourBackPositionStart.z);
          this.scene.objects.hive4.rotation.x = hiveFourBackPositionStart.b;
          this.scene.objects.hive4.position.y = hiveFourBackPositionStart.c;
        })
        .onComplete(() => {
          this.scene.status.scene = 'playing';
        })
        .delay(500)
        .start();

      const cameraBackPositionStart = {
        x: this.scene.objects.cam.position.x,
        y: this.scene.objects.cam.position.y,
        z: this.scene.objects.cam.position.z,
      };
      this.tween.cameraMoveTwo = new TWEEN.Tween(cameraBackPositionStart)
        .to({
          x: this.anchor.cam.px,
          y: this.anchor.cam.py,
          z: this.anchor.cam.pz,
        }, 3200)
        .easing(TWEEN.Easing.Quintic.InOut)
        .onUpdate(() => {
          this.scene.objects.cam.position.set(cameraBackPositionStart.x, cameraBackPositionStart.y, cameraBackPositionStart.z);
        })
        .onComplete(() => {
          this.cameraPanRight();
          this.status.canScroll = true;
        })
        .start();

      const lookAtBackPositionStart = {
        x: this.scene.objects.target.position.x,
        y: this.scene.objects.target.position.y,
        z: this.scene.objects.target.position.z,
      };
      this.tween.targetMoveTwo = new TWEEN.Tween(lookAtBackPositionStart)
        .to({
          x: this.anchor.target.px,
          y: this.anchor.target.py,
          z: this.anchor.target.pz,
        }, 3200)
        .easing(TWEEN.Easing.Quintic.InOut)
        .onUpdate(() => {
          this.scene.objects.target.position.set(lookAtBackPositionStart.x, lookAtBackPositionStart.y, lookAtBackPositionStart.z);
        })
        .start();

      TweenMax.to($('#h1-sec .slideup-ctrl'), 1, {
        opacity: 1,
        y: 0,
        delay: 1.2,
        ease: Expo.easeOut,
        onStart: () => {
          document.querySelector('.scrolltofirst').classList.add('fade');
        },
      });
      $('#h1-sec .slideup').each(function (index) {
        const titleBackDelay = index / 6 + 0.7;
        TweenMax.to($(this), 1, {
          y: 0, opacity: 1, delay: titleBackDelay, ease: Expo.easeOut,
        });
      });
      TweenMax.to($('#site'), 1.6, {
        y: window.innerHeight,
        ease: Expo.easeInOut,
      });
    }
  }

  loadScene(model) {
    this.scene = new BeeScene(this, model);
    this.setupScene();
    new TWEEN.Tween(this.scene.objects.bee.rotation)
      .to(100, 100)
      .easing(TWEEN.Easing.Quintic.Out)
      .onStart(() => this.status.beeIsTweening = true)
      .onComplete(() => this.status.beeIsTweening = false)
      .start();
    this.scene.setSize(window.innerWidth, window.innerHeight);
    this.scene.start();
  }
}

module.exports = Bee;

/**
 * Clients
 * -------
 */

import React from 'react';
import styled from 'styled-components';

import Block from '../Block';

import clientsSmall from '../../../static/img/graphics/clients-small.svg';
import clientsMobile from '../../../static/img/graphics/clients-mobile.svg';
import clientsDesktop from '../../../static/img/graphics/clients-desktop.svg';

const conf = {
  mobileAt: '320px',
  desktopAt: '640px',
};

const Wrapper = styled.div`
  width: 100%;
  img {
    pointer-events: none;
    width: 100%;
    opacity: 0.2;
    display: none;
    &:nth-child(1) {
      @media (max-width: ${conf.mobileAt}) {
        display: block;
      }
    }
    &:nth-child(2) {
      @media (min-width: ${conf.mobileAt}) and (max-width: ${conf.desktopAt}) {
        display: block;
      }
    }
    &:nth-child(3) {
      @media (min-width: ${conf.desktopAt}) {
        display: block;
      }
    }
  }
`;

const Clients = () => (
  <Block m="auto" py={6} px={3} maxWidth="900px">
    <Wrapper>
      <img src={clientsSmall} alt="" />
      <img src={clientsMobile} alt="" />
      <img src={clientsDesktop} alt="" />
    </Wrapper>
  </Block>
);

export default Clients;

/**
 * Contact
 * -------
 * extends: Block
 * props:
 *  - as: [string] `[a]` **required**
 */

import React from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import Icon from '../Icon';
import Block from '../Block';
import Sitewidth from '../Sitewidth';
import { Link } from '../Text';

import Informations from './Informations';

const NAV_ITEMS = [
  { name: 'Home', to: '/' },
  { name: 'About', to: '/about' },
  { name: 'Services', to: '/services' },
  { name: 'Portfolio', to: '/portfolio' },
  { name: 'Contact', to: '/contact' },
];

const SOCIAL_ITEMS = [
  {
    name: 'facebook',
    url: 'https://www.facebook.com/DXBeeMedia',
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/dxbeemedia',
  },
];

const Wrapper = styled(Block)`
  font-weight: ${themeGet('fontWeights.heavy')};

  @media(max-width: ${themeGet('breakpoints.1')}) {
    flex-direction: column;
    align-items: flex-start;

    &:first-child {
      margin-bottom: 20px;
    }
  }
`;

const StyledLink = styled(Link)`
  margin-right: 20px; 
  
  @media(max-width: ${themeGet('breakpoints.0')}) {
    &:not(:last-child) { margin-right: 20px; }
  }
`;

const Nav = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  justify-content: center;
  @media(max-width: ${themeGet('breakpoints.0')}) {
    a {
      width: 33%;
    }

    a:nth-child(-n + 5) {
      margin-bottom: 30px;
    }

    &:first-child {
      margin-bottom: 0 !important;
    }
  }
  @media(max-width: ${themeGet('breakpoints.1')}) {
    &:first-child {
      margin-bottom: 10px;
    }
  }
`;

const Social = styled.div`
  padding: 25px 0;

  @media(min-width: ${themeGet('breakpoints.1')}) {
    padding: 0;
  }

  a:last-child {
    margin-right: 0 !important;
  }
`;

const Body = () => (
  <Wrapper py={[5, 6]}>
    <Sitewidth
      flexDirection={['column', null, 'row']}
      alignItems={['center', null, 'flex-start']}
      justifyContent="space-between"
    >
      <Nav>
        {NAV_ITEMS.map(item => (
          <AniLink
            key={item.name}
            cover
            to={item.to}
            bg="hsl(42,100%,55%)"
          >
            <StyledLink as="div">
              {item.name}
            </StyledLink>
          </AniLink>
        ))}
      </Nav>
      <Social>
        {SOCIAL_ITEMS.map(item => (
          <StyledLink
            key={item.name}
            as="a"
            href={item.url}
            target="_blank"
          >
            <Icon name={item.name} />
          </StyledLink>
        ))}
      </Social>
    </Sitewidth>
    <Informations />
  </Wrapper>
);

export default Body;

import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import GlobalStyle from './GlobalStyle';
import Navigation from './Navigation';
import Footer from './Footer';
import ShitHeadFix from './ShitHeadFix';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(
            filter: {fileAbsolutePath: {regex: "/portfolio/"}},
            limit: 5
            )
          {
          edges {
            node {
              frontmatter {
                title
                slug
                general {
                  category
                }
                header {
                  cover {
                    image {
                      ...imageFragment
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <ShitHeadFix>
        <Helmet title={data.site.siteMetadata.title} />
        <ThemeProvider theme={{ mode: 'light' }}>
          <GlobalStyle>
            <Navigation portfolio={data.allMarkdownRemark.edges} />
            {children}
            <Footer />
          </GlobalStyle>
        </ThemeProvider>
      </ShitHeadFix>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

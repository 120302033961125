import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SVG = styled.svg`
  stroke: ${({ color }) => color};
  transition: stroke 300ms;

  &:hover {
    stroke: ${({ hoverColor }) => hoverColor};
  }
`;

const Icon = ({ name, color, hoverColor }) => {
  switch (name) {
    case 'instagram':
      return (
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-instagram"
          color={color}
          hoverColor={hoverColor}
        >
          <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
          <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
          <line x1="17.5" y1="6.5" x2="17.5" y2="6.5" />
        </SVG>
      );
    default: // facebook
      return (
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-facebook"
          color={color}
          hoverColor={hoverColor}
        >
          <path
            d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"
          />
        </SVG>
      );
  }
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
};

Icon.defaultProps = {
  color: 'hsl(0, 0%, 0%)',
  hoverColor: 'hsl(42,100%,55%)',
};

export default Icon;

import React from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';

import Block from '../Block';
import Sitewidth from '../Sitewidth';
import { Link } from '../Text';

const StyledLink = styled(Link)`
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const Wrapper = styled(Block)`
  ${StyledLink} { font-weight: ${themeGet('fontWeights.light')}; };
  line-height: 1.6;
  text-align: center;
  font-size: 14px;
`;

const Info = styled.div`
  display: flex;
  align-items: center;

  margin-top: 5px;
  @media(max-width: ${themeGet('breakpoints.1')}) {
    flex-direction: column;
    margin-top: 0;

    & > a {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

const Informations = () => (
  <Sitewidth>
    <Wrapper pt={[4, 5]} alignItems={['center', null, 'flex-start']}>
      <StyledLink
        href="https://www.google.ae/maps/place/DXBEE+Media/@25.097809,
        55.1557475,19z/data=!3m1!4b1!4m5!3m4!1s0x3e5f6b417d3fff7f:
        0xbc0572be15d2415d!8m2!3d25.097809!4d55.156296?hl=en"
        target="_blank"
      >
        Building 4, 2nd Floor, Bay Square, Dubai, UAE
      </StyledLink>
      <Info>
        <StyledLink href="mailto:hello@dxbee.io">hello@dxbee.io</StyledLink>
        <div>
          <StyledLink href="tel:+971559999583">+971 55 9999 583</StyledLink>
        </div>
      </Info>
    </Wrapper>
  </Sitewidth>
);

export default Informations;

/*! modernizr 3.5.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-canvas-csscalc-passiveeventlisteners-webgl-setclasses ! */
!(function (e, n, t) {
  function s(e, n) { return typeof e === n; } function a() {
    let e; let n; let t; let a; let o; let i; let
      c; for (const f in l) if (l.hasOwnProperty(f)) { if (e = [], n = l[f], n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length)) for (t = 0; t < n.options.aliases.length; t++)e.push(n.options.aliases[t].toLowerCase()); for (a = s(n.fn, 'function') ? n.fn() : n.fn, o = 0; o < e.length; o++)i = e[o], c = i.split('.'), c.length === 1 ? Modernizr[c[0]] = a : (!Modernizr[c[0]] || Modernizr[c[0]] instanceof Boolean || (Modernizr[c[0]] = new Boolean(Modernizr[c[0]])), Modernizr[c[0]][c[1]] = a), r.push((a ? '' : 'no-') + c.join('-')); }
  } function o(e) {
    let n = f.className; const
      t = Modernizr._config.classPrefix || ''; if (u && (n = n.baseVal), Modernizr._config.enableJSClass) { const s = new RegExp(`(^|\\s)${t}no-js(\\s|$)`); n = n.replace(s, `$1${t}js$2`); }Modernizr._config.enableClasses && (n += ` ${t}${e.join(` ${t}`)}`, u ? f.className.baseVal = n : f.className = n);
  } function i() { return typeof n.createElement !== 'function' ? n.createElement(arguments[0]) : u ? n.createElementNS.call(n, 'https://www.w3.org/2000/svg', arguments[0]) : n.createElement.apply(n, arguments); } var r = []; var l = []; const c = {
    _version: '3.5.0',
    _config: {
      classPrefix: '', enableClasses: !0, enableJSClass: !0, usePrefixes: !0,
    },
    _q: [],
    on(e, n) { const t = this; setTimeout(() => { n(t[e]); }, 0); },
    addTest(e, n, t) { l.push({ name: e, fn: n, options: t }); },
    addAsyncTest(e) { l.push({ name: null, fn: e }); },
  }; var
    Modernizr = function () {}; Modernizr.prototype = c, Modernizr = new Modernizr(); var f = n.documentElement; var
    u = f.nodeName.toLowerCase() === 'svg'; Modernizr.addTest('canvas', () => { const e = i('canvas'); return !(!e.getContext || !e.getContext('2d')); }), Modernizr.addTest('webgl', () => {
    const n = i('canvas'); const
      t = 'probablySupportsContext' in n ? 'probablySupportsContext' : 'supportsContext'; return t in n ? n[t]('webgl') || n[t]('experimental-webgl') : 'WebGLRenderingContext' in e;
  }); const p = c._config.usePrefixes ? ' -webkit- -moz- -o- -ms- '.split(' ') : ['', '']; c._prefixes = p, Modernizr.addTest('csscalc', () => {
    const e = 'width:'; const n = 'calc(10px);'; const
      t = i('a'); return t.style.cssText = e + p.join(n + e), !!t.style.length;
  }), Modernizr.addTest('passiveeventlisteners', () => { let n = !1; try { const t = Object.defineProperty({}, 'passive', { get() { n = !0; } }); e.addEventListener('test', null, t); } catch (s) {} return n; }), a(), o(r), delete c.addTest, delete c.addAsyncTest; for (let d = 0; d < Modernizr._q.length; d++)Modernizr._q[d](); e.Modernizr = Modernizr;
}(window, document));

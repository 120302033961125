import React from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import PropTypes from 'prop-types';

import { ease } from '../../utils';
import ArrowRight from '../../assets/icons/arrow-right.svg';

const Wrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 26px;
  margin-bottom: 50px;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 26px;
  background: ${themeGet('colors.primary.gradient')};
  cursor: pointer;

  &:after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url(${ArrowRight}) no-repeat center;
    transform: ${props => (props.direction === 'up'
    ? 'rotate(-90deg)'
    : 'rotate(90deg)'
  )};
    background-size: contain;
    opacity: 1;
    transition: transform 800ms ${ease('out', 'circ')};
  };

  &:hover {
    &:after { transform: ${props => (props.direction === 'up'
    ? 'rotate(-90deg) translateX(5px)'
    : 'rotate(90deg) translateX(5px)')
}}
  }
`;

const Round = ({
  text, id, className, direction,
}) => (
  <Wrapper>
    <Button id={id} className={className} direction={direction}>
      {text}
    </Button>
  </Wrapper>
);

Round.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
};

Round.defaultProps = {
  text: '',
};

export default Round;

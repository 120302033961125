import React, { Component } from 'react';
import styled from 'styled-components';

import DXBEE from './index';
import Letters from '../Letters';

import { Label } from '../Text';
import Sitewidth from '../Sitewidth';
import Round from '../Button/Round';
import Space from '../Space';

const FullContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const TextContainer = styled.div`
  align-self: center;
`;

const StyledLabel = styled(Label)`
  font-weight: 400;
  font-style: italic;
  font-size: 1.2em;
  line-height: 1.6;
`;

export default class BeeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!window.dxbeeCount) {
      window.dxbeeCount = 0;
    }
    switch (window.dxbeeCount) {
      case 0:
        // skip
      break;
      case 1:
        window.dxbee = this.dxbee = new DXBEE();
      break;
      default:
        window.location.reload(); // dobar si
    }
    window.dxbeeCount++;
  }

  render() {
    return (
      <>
        <div id="preloader">
          <div className="preloader-bg plbg-1 shift" />
          <div className="preloader-bg plbg-2 shift" />
          <div className="preloader-content">
            <div className="plc-index">
              <div className="loader">
                <svg height="100" width="100">
                  <circle cx="50" cy="50" r="26" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div id="bee-canvas" />
        <section id="h1-sec" className="full-sec home-bee">
          <Sitewidth height="100%">
            <FullContainer className="full-container">
              <TextContainer className="center-left-content dark-content perspective">
                <Letters />
                <Space height="20px" />
                <div className="slideup-ctrl">
                  <StyledLabel id="click-n-hold">- hold the bee or scroll -</StyledLabel>
                </div>
              </TextContainer>
              <div className="scrolltofirst fade">
                <div className="scroll-btn">
                  <Round
                    id="moveToHome"
                    className="move-to-home btn btn-primary btn-sq-dark"
                    direction="bottom"
                  />
                </div>
              </div>
            </FullContainer>
            <svg id="holdProgress" width="50" height="50" viewport="0 0 50 50" version="1.1" xmlns="https://www.w3.org/2000/svg">
              <circle r="24" cx="25" cy="25" fill="transparent" strokeDasharray="150.796" />
              <circle id="hpBar" r="24" cx="25" cy="25" fill="transparent" strokeDasharray="150.796" />
            </svg>
          </Sitewidth>

        </section>
      </>
    );
  }
}

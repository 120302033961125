import { useStaticQuery, graphql } from 'gatsby';

export default function featuredProjects() {
  const data = useStaticQuery(graphql`
    query settings {
      settings: markdownRemark(fileAbsolutePath: {regex: "/settings/"}) {
        frontmatter {
          featuredProjects {
            slug
          }
        }
      }
      projects: allMarkdownRemark(
        filter: { fileAbsolutePath: {regex: "/portfolio/"}}
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
              general {
                category
                subcategory
              }
              header {
                cover {
                  image {
                    ...imageFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { settings, projects } = data;

  return settings.frontmatter.featuredProjects.map(item => (
    projects.edges.find(project => item.slug === project.node.frontmatter.slug)
  ));
}

/**
 * Projects
 * --------
 * extends: Block, TextBlock
 * props:
 * - projects: [arrayOf(object)] || [node] **required**
 * - title: [string] **required**
 * - label: [string] **required**
 * - quote: [string]
 * - order: [string] `[title]`
 * - color: [string] `[default]`
 */

import React, { Component } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { timingFunctions } from 'polished';
import { themeGet } from 'styled-system';
import PropTypes from 'prop-types';

import Sitewidth from '../Sitewidth';
import Block from '../Block';
import TextBlock from '../TextBlock';
import { Title } from '../Text';

import Slider from './Slider';
import AnimationHorizontal from '../Animate';

const Overflow = styled.div`
  overflow: hidden;
  width: 100%;
`;

const Wrapper = styled(Block)`
  align-items: flex-start;
  justify-content: flex-start;
  background: ${themeGet('colors.secondary.base')};
`;

const Boxes = styled.div`
  position: relative;
  left: 50%; top: 125%;

  @media(max-width: 400px) {
    left: 21px;
    top: 130%;
  }
`;

const Frame = styled(Slider)`
  ${props => props.onDragStart && css`
    cursor: grabbing;
  `}
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  margin-top: 30px;

  pointer-events: ${props => (props.pointerEvents ? 'all' : 'none')};
  
  margin-right: 20px;
  
  &:after {
    content: '';
    position: absolute;
    background: ${themeGet('colors.black.shade.2')};
    top: 0; right: 0; bottom: 0; left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    transition: opacity 1000ms;
    mask-image:
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  }

  &:hover:after {
    opacity: 0;
  }

  @media(max-width: ${themeGet('breakpoints.0')}) {
    width: 270px;
    height: 270px;
  }
`;

const Image = styled(Img)`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(1.1);
  transition: transform 3000ms ${timingFunctions('easeOutExpo')};
  &:hover {
    transform: scale(1);
  }
`;

// const ImageLogo = styled(Img)`
//   width: 100%;
//   height: 100%;
//   max-width: 200px;
//   margin: 0 auto;
// `;

const Heading = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  z-index: 1;
`;

class Projects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slideIndex: 0,
      pointerEvents: true,
    };
  }

  setSlideIndex = (slideIndex) => {
    this.setState({ slideIndex });
  };

  // @NOTE TEMPORARY HOTFIX
  handleDragStart = () => setTimeout(() => {
    this.setState({ pointerEvents: false });
  }, 100);

  handleDragEnd = () => this.setState({ pointerEvents: true });

  render() {
    const {
      title, label, quote, order, color,
    } = this.props;
    const { slideIndex, pointerEvents } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query allProjects {
            allMarkdownRemark(
                filter: {fileAbsolutePath: {regex: "/projects/"}})
              {
              edges {
                node {
                  frontmatter {
                    date
                    # title
                    # subtitle
                    url
                    # logo {
                    #   ...imageFragment
                    # }
                    image {
                      ...imageFragment
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <ThemeProvider theme={{ mode: 'dark' }}>
            <Overflow>
              <Wrapper py={[5, 6, 7]}>
                <Sitewidth>
                  <TextBlock
                    title={title}
                    label={label}
                    quote={quote}
                    order={order}
                    color={color}
                  />
                  <Boxes>
                    <Frame
                      slideIndex={slideIndex}
                      onSlideChange={this.setSlideIndex}
                      onDragStart={this.handleDragStart}
                      onDragEnd={this.handleDragEnd}
                    >
                      {data.allMarkdownRemark.edges.map((item, index) => {
                        const { frontmatter } = item.node;
                        return (
                          <AnimationHorizontal
                            key={frontmatter.date}
                            direction="btt"
                            delay={index * 50}
                          >
                            <Box
                              as="a"
                              href={frontmatter.url}
                              target="_blank"
                              // temporary fix for console errors
                              pointerEvents={pointerEvents ? 1 : 0}
                            >
                              <Image
                                fluid={frontmatter.image.childImageSharp.fluid}
                                alt={frontmatter.title || frontmatter.url}
                              />
                              {/* <Heading>
                                {frontmatter.logo && (
                                  <ImageLogo
                                    fluid={frontmatter.logo.childImageSharp.fluid}
                                    alt={frontmatter.title}
                                  />
                                )}
                                {frontmatter.title
                                  && <Title as="h3">{frontmatter.title}</Title>
                                }
                                {frontmatter.subtitle
                                  && <Title as="h6">{frontmatter.subtitle}</Title>
                                }
                              </Heading> */}
                            </Box>
                          </AnimationHorizontal>
                        );
                      })}
                    </Frame>
                  </Boxes>
                </Sitewidth>
              </Wrapper>
            </Overflow>
          </ThemeProvider>
        )}
      />
    );
  }
}

Projects.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  quote: PropTypes.string,
  order: PropTypes.string,
  color: PropTypes.string,
};

Projects.defaultProps = {
  order: 'title',
  quote: '',
  color: '',
};

export default Projects;

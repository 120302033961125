/**
 * Footer
 * ------
 */

import React from 'react';
import { ThemeProvider } from 'styled-components';

import Body from './Body';
import Copyright from './Copyright';

const Footer = () => (
  <ThemeProvider theme={{ mode: 'dark' }}>
    <>
      <Body />
      <Copyright />
    </>
  </ThemeProvider>
);

export default Footer;

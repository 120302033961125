/**
 * Title
 * -----
 * extends: Common
 * themed:
 *  - mode `light || dark`
 */

import styled from 'styled-components';
import themed from 'styled-theming';
import styledProps from 'styled-props';
import { themeGet, fontSize, fontWeight } from 'styled-system';

import Common from './Common';

const Title = styled(Common)`
  line-height: 1.2;

  font-size: ${styledProps({
    h1: themeGet('fontSizes.11'),
    h2: themeGet('fontSizes.10'),
    h3: themeGet('fontSizes.9'),
    h4: themeGet('fontSizes.7'),
    h5: themeGet('fontSizes.6'),
    h6: themeGet('fontSizes.0'),
  }, 'as')};
  font-weight: ${themeGet('fontWeights.bold')};

  color: ${themed('mode', {
    light: themeGet('colors.secondary.base'),
    dark: themeGet('colors.white.base'),
  })};
  font-family: ${themeGet('fontFamilies.sans')};

  ${fontSize};
  ${fontWeight};

  @media(max-width: ${themeGet('breakpoints.1')}) {
    font-size: ${themeGet('fontSizes.8')};
  }
`;

Title.defaultProps = {
  as: 'h2',
};

export default Title;

/**
 * Label
 * -----
 * extends: Common
 * themed:
 *  - mode `light || dark`
 */

import styled from 'styled-components';
import { themeGet } from 'styled-system';
import themed from 'styled-theming';

import Common from './Common';

const Label = styled(Common)`
  font-style: italic;
  line-height: 1.6;

  font-size: ${themeGet('fontSizes.1')};
  font-weight: ${themeGet('fontWeights.regular')};

  color: ${themed('mode', {
    light: themeGet('colors.secondary.base'),
    dark: themeGet('colors.white.shade.0'),
  })};
`;

export default Label;

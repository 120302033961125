import React from 'react';
import styled from 'styled-components';
import useTypewriter from 'use-typewriter';
import { themeGet } from 'styled-system';

import { Title } from '../Text';

const StyledTitle = styled(Title)`
  font-size: 4.8em;
  line-height: 1.1;
  font-weight: 800;
  max-width: 660px;
`;

const Cursor = styled.span`
  display: inline-block;
  width: 5px;
  height: 60px;
  background: ${themeGet('colors.primary.base')};
  animation: blink 1s linear infinite;
  transform: translate(5px, 0);
  @media(max-width: ${themeGet('breakpoints.0')}) {
    height: 30px;
    width: 3px;
  }
  @keyframes blink { 50% { opacity: 0 }}
`;

const Letters = () => {
  const sentences = useTypewriter({
    words: [
      ' world-class digital products.',
      ' internet of things!',
      ' quality tested software!',
    ],
  });

  return (
    <StyledTitle className="slideup">
      We build{sentences}
      <Cursor />
    </StyledTitle>
  );
};

export default Letters;

/**
 * Sitewidth
 * ---------
 * props:
 *  - m: [string] (default: 'auto')
 *  - maxWidth: [string] (default: '1320px')
 *  - px: [array] (default: [4, 5, 6])
 */
import React from 'react';

import Block from './Block';

const Sitewidth = React.forwardRef(
  (props, ref) => <Block ref={ref} {...props} />,
);

Sitewidth.defaultProps = {
  m: 'auto',
  maxWidth: '1320px',
  px: [4, 5, 6],
};

export default Sitewidth;

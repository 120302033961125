/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
const THREE = require('three');
const TWEEN = require('tween.js');

class BeeScene {
  constructor(controller, model) {
    this.controller = controller;
    this.objectLoader = new THREE.ObjectLoader();
    this.renderer = null;
    this.raycaster = null;
    this.controls = null;
    this.camera = null;
    this.scene = null;
    this.effects = null;
    this.events = {};
    this.width = 500;
    this.height = 500;

    this.cameraVR = null;
    this.isVR = null;

    this.status = {};
    this.status.scene = null;
    this.status.bee = null;
    this.status.beeIsTweening = false;

    this.animation = {
      loop: null,
      call: () => {
        this.render();
        this.animation.loop = requestAnimationFrame(this.animation.call);
      },
      timeLast: performance.now(),
      timeDelta: 0,
    };

    this.objects = {};

    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setClearColor(0xFFFFFF, 0);


    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.autoClear = false;
    this.renderer.sortObjects = false;
    this.renderer.gammaInput = this.renderer.gammaInput && !!model.project.gammaInput;
    this.renderer.gammaOutput = this.renderer.gammaOutput && !!model.project.gammaOutput;
    this.renderer.shadowMap.enabled = this.renderer.shadowMap.enabled && !!model.project.shadows;

    this.raycaster = new THREE.Raycaster();

    this.setScene(this.objectLoader.parse(model.scene));
    this.setCamera(this.objectLoader.parse(model.camera));

    // Define references to separate bee scene objects

    this.objects.bee = this.scene.getObjectByName('bee', true);
    this.objects.bee.startPosition = {};
    this.objects.bee.startPosition.y = this.objects.bee.position.y;
    this.objects.bee.startRotation = {};
    this.objects.bee.startRotation.x = this.objects.bee.rotation.x;
    this.objects.bee.startRotation.y = this.objects.bee.rotation.y;
    this.objects.cam = this.scene.getObjectByName('cam', true);
    this.objects.cam.startPositionZ = this.objects.cam.position.z;
    this.objects.target = this.scene.getObjectByName('CenterPoint', true);
    this.objects.target.startPositionX = this.objects.target.position.x;
    this.objects.head1 = this.scene.getObjectByName('HEAD_01', true);
    this.objects.head2 = this.scene.getObjectByName('HEAD_02', true);
    this.objects.beeBody = this.scene.getObjectByName('BODY', true);
    this.objects.beeEyes = this.scene.getObjectByName('EYES', true);
    this.objects.antenna1 = this.scene.getObjectByName('Antennas_11', true);
    this.objects.antenna2 = this.scene.getObjectByName('Antennas_1', true);
    this.objects.hive1 = this.scene.getObjectByName('hive1', true);
    this.objects.hive2 = this.scene.getObjectByName('hive2', true);
    this.objects.hive3 = this.scene.getObjectByName('hive3', true);
    this.objects.hive4 = this.scene.getObjectByName('hive4', true);
    this.objects.hive5 = this.scene.getObjectByName('hive5', true);
    this.objects.hive6 = this.scene.getObjectByName('hive6', true);
    this.objects.hive7 = this.scene.getObjectByName('hive7', true);
    this.objects.wingRight = this.scene.getObjectByName('WING', true);
    this.objects.wingRight.startRotation = {};
    this.objects.wingRight.startRotation.y = this.objects.wingRight.rotation.y;
    this.objects.wingRight.startPosition = {};
    this.objects.wingRight.startPosition.x = this.objects.wingRight.position.x;
    this.objects.wingRight.startPosition.y = this.objects.wingRight.position.y;
    this.objects.wingRight.startPosition.z = this.objects.wingRight.position.z;
    this.objects.wingLeft = this.scene.getObjectByName('WING_1', true);
    this.objects.wingLeft.startRotation = {};
    this.objects.wingLeft.startRotation.y = this.objects.wingLeft.rotation.y;
    this.objects.wingLeft.startPosition = {};
    this.objects.wingLeft.startPosition.x = this.objects.wingLeft.position.x;
    this.objects.wingLeft.startPosition.y = this.objects.wingLeft.position.y;
    this.objects.wingLeft.startPosition.z = this.objects.wingLeft.position.z;
    this.objects.perspectiveCamera = this.scene.getObjectByName('PerspectiveCamera 1', true);

    this.recalculatePosition();

    this.status.bee = 'static';
    this.setCamera(this.objects.perspectiveCamera);

    this.events = {
      init: [],
      start: [],
      stop: [],
      keydown: [],
      keyup: [],
      mousedown: [],
      mouseup: [],
      mousemove: [],
      touchstart: [],
      touchend: [],
      touchmove: [],
      update: [],
    };
  }

  /**
   * Sets the scene
   */
  setScene(scene) {
    this.scene = scene;
  }

  /**
   * Sets the camera
   */
  setCamera(camera) {
    this.camera = camera;
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
  }

  /**
   * Sets the scene dimensions
   */
  setSize(width, height) {
    this.width = width;
    this.height = height;
    if (this.camera) {
      this.camera.aspect = this.width / this.height;
      this.camera.updateProjectionMatrix();
    }
    if (this.renderer) {
      this.renderer.setSize(width, height);
    }
    if (window.matchMedia('(orientation: portrait)').matches) {
      if (window.innerWidth < 820 && window.innerWidth > 520) {
        this.objects.target.startPositionX = 0 - (window.innerWidth / 12 - 16);
        this.objects.cam.startPositionZ = 300 - (window.innerWidth - 820) / 6;
      }
      if (window.innerWidth <= 520) {
        this.objects.target.startPositionX = 0 - (window.innerWidth / 12 - 16);
        this.objects.cam.startPositionZ = 300 - (window.innerWidth - 820) / 4;
      }
      if (window.innerWidth >= 820) {
        this.objects.target.startPositionX = -48.09;
        this.objects.cam.startPositionZ = 300;
      }
    } else {
      this.objects.target.startPositionX = -48.09;
      this.objects.cam.startPositionZ = 300;
    }
  }

  /**
   * Recalculate positions
   */
  recalculatePosition() {
    this.setSize(this.width, this.height);
  }

  /**
   * Append renderer canvas to the container
   */
  appendTo(target) {
    target.appendChild(this.renderer.domElement);
  }

  /**
   * Dispatch an event
   */
  dispatch(name) {
    if (this.events[name]) {
      this.events[name].forEach(handler => handler(this));
    }
  }

  /**
   * Animate single frame
   */
  render(time) {
    this.animation.timeDelta = this.animation.time - this.animation.timeLast;
    try {
      this.dispatch('update');
    } catch (e) {
      console.error(e);
    }
    this.renderer.clear();
    this.renderer.render(this.scene, this.camera);
    TWEEN.update();
    this.animation.timeLast = this.animation.time;
  }

  /**
   * Start the loop
   */
  start() {
    this.dispatch('start');
    this.animation.time = performance.now();
    this.animation.timeLast = this.animation.time;
    this.animation.timeDelta = 0;
    this.animation.loop = requestAnimationFrame(this.animation.call);
  }

  /**
   * Stop
   */
  stop() {
    this.dispatch('stop');
    cancelAnimationFrame(this.animation.loop);
  }

  /**
   * Dispatch
   * @TODO: Do manual garbage collection (cleanup references and so on)
   */
  dispose() {
    this.renderer.dispose();
  }
}

module.exports = BeeScene;

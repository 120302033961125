/**
 * Paragraph
 * -----
 * extends: Common
 * themed:
 *  - mode `light || dark`
 */

import styled from 'styled-components';
import themed from 'styled-theming';
import { themeGet, maxWidth } from 'styled-system';

import Common from './Common';

const Paragraph = styled(Common)`
  font-weight: ${themeGet('fontWeights.light')};
  line-height: 1.6;
  color: ${themed('mode', {
    light: themeGet('colors.secondary.base'),
    dark: themeGet('colors.white.shade.0'),
  })};

  font-family: ${themeGet('fontFamilies.sans')};
  ${maxWidth}
`;

export default Paragraph;
